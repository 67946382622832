
//component
.talkk-web-chatbot-main-wrap{
    .loading-comp{
        background: rgb(255,255,255,0.1) !important;
        height: 100% !important;
        width: 100% !important;
        left: 0 !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        position: relative !important;
        padding: 0 !important;
        margin: 0 !important;
        position: absolute !important;
        top: 0 !important;
        margin-top: 72px !important;
        max-height: calc(100% - 71px) !important;
        z-index: 1 !important;
        border-radius: 0 0 20px 20px !important;
        img{
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            width: 100px !important;
            text-align: center !important;
        }  
    }
    .loading-comp img{
        width: 250px !important;
    }
}
@import url("./components/minibot.scss");
@import url("./vendor/icon.scss");
@import url("./vendor/ttd/style.scss");

@import url("./components/core.scss");
@import url("./components/selectLang.scss");
@import url("./components/button.scss");
@import url("./components/header.scss");
@import url("./components/footer.scss");
@import url("./components/welcome-screen.scss");
@import url("../scss/components/feedback.scss");
@import url("./components/_placeholder.scss");
//page
@import url("./components/chatwidget.scss");
@import url("./components/_theme.scss");
@import url("./pages/_talkk_to_docs.scss");
@import url("./pages/_theme-mu.scss");
@import url("./components/_swiper.scss");
@import url("./pages/esoft.scss");
.talkk-web-chatbot-main-wrap{
    .loading-comp img{
        width: 300px !important;
    }
}