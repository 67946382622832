@font-face {
    font-family: 'bot';
    src:  url('../../fonts/bot.eot?c0bh9w');
    src:  url('../../fonts/bot.eot?c0bh9w#iefix') format('embedded-opentype'),
      url('../../fonts/bot.ttf?c0bh9w') format('truetype'),
      url('../../fonts/bot.woff?c0bh9w') format('woff'),
      url('../../fonts/bot.svg?c0bh9w#bot') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  .bot {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'bot' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-star--Fill:before {
    content: "\e900";
  }
  .icon-star-stroke:before {
    content: "\e901";
  }
  .icon-mic:before {
    content: "\e902";
  }
  .icon-attachment:before {
    content: "\e903";
  }
  .icon-fr:before {
    content: "\e904";
  }
  .icon-en:before {
    content: "\e905";
  }
  .icon-mood:before {
    content: "\e906";
  }
  .icon-library-books:before {
    content: "\e907";
  }
  .icon-cr:before {
    content: "\e908";
  }
  .icon-send:before {
    content: "\e909";
  }
  .icon-refresh:before {
    content: "\e90a";
  }
  .icon-keyboard:before {
    content: "\e90b";
  }
  .icon-search:before {
    content: "\e90c";
  }
  .icon-bot:before {
    content: "\e90d";
  }
  .icon-pencil:before {
    content: "\e90e";
  }
  .icon-comment-new:before {
    content: "\e90f";
  }
  .icon-menu:before {
    content: "\e910";
  }
  .icon-thumbs-up:before {
    content: "\e911";
  }
  .icon-uniE910:before {
    content: "\e912";
  }
  .icon-uniE911:before {
    content: "\e913";
  }
  .icon-uniE912:before {
    content: "\e914";
  }
  .icon-industry:before {
    content: "\e915";
  }
  .icon-headset-1:before {
    content: "\e916";
  }
  .icon-delivery-1:before {
    content: "\e917";
  }
  .icon-global-1:before {
    content: "\e918";
  }
  .icon-ecology-and-environment:before {
    content: "\e919";
  }
  .icon-consumer:before {
    content: "\e91a";
  }
  .icon-african-heritage-house:before {
    content: "\e91b";
  }
  .icon-agriculture-1:before {
    content: "\e91c";
  }
  .icon-technical-support-1:before {
    content: "\e91d";
  }
  .icon-close-2-1:before {
    content: "\e91e";
  }
  .icon-star-o:before {
    content: "\e91f";
  }
  .icon-uniE91E:before {
    content: "\e920";
  }
  .icon-uniE91F:before {
    content: "\e921";
  }
  .icon-uniE920:before {
    content: "\e922";
  }
  .icon-uniE921:before {
    content: "\e923";
  }
  .icon-uniE922:before {
    content: "\e924";
  }
  .icon-uniE923:before {
    content: "\e925";
  }
  .icon-link:before {
    content: "\e926";
  }
  .icon-check:before {
    content: "\1f326";
  }
  