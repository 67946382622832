.talkk-chat-wrapper-c {
    .btn-default {
        color: var(--btn-color) !important;
        cursor: pointer !important;
        background-color: var(--btn-bg) !important;
        border: 1px solid var(--btn-bg) !important;
        line-height: normal !important;

        &.btn-rounded-full {
            border-radius: 20px !important;
            width: 100% !important;
            padding: 10px !important;
        }

        &.br-4 {
            border-radius: 4px !important;
        }
    }

    .btn-outline {
        padding: 7px 12px !important;
        border-radius: 22px !important;
        font-size: 13px !important;
        color: #fff !important;
        background-color: rgba(255, 255, 255, 0.2) !important;
        border: none !important;
        transition: 0.3s all linear !important;
        /* display: flex !important; */
        font-weight: bold !important;
        align-items: center !important;
        display: block !important;
        --webkit-appearance: none !important;
        text-align: left !important;
        line-height: normal !important;

        &:hover {
            color: var(--btn-hover-color) !important;
            background-color: var(--btn-hover-bg) !important;
            border-color: var(--btn-hover-bg) !important;
            box-shadow: 0 0 0 0.2rem rgba(var(--primary-color), 0.5) !important;
        }

        &.btn-disabled {
            background-color: #BBBBBB !important;
            border-color: #BBBBBB !important;
            color: rgba(85, 85, 85, 0.5) !important;
            cursor: not-allowed !important;
        }
    }
}