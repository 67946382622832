#menu-phoneInputWithCountrySelectCountry.MuiPopover-root {
    z-index: 999999 !important;
    width: 300px !important;
    height: 400px !important;
}

.talkk-chat-wrapper-c {
    .welcome-wrapper {
        // padding: 15px !important;
        // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
        margin: 20px !important;
        border-radius: 6px !important;
        // background-color: #fff !important;
        position: absolute !important;
        width: 100% !important;
        left: 0 !important;
        margin: 0 !important;
        z-index: 99 !important;
        height: calc(100% - 73px) !important;

        form {
            height: calc(100% - 38px) !important;
        }

        .welcome-greeting{
            font-size: 26px !important;
            text-align: center;
        }
        .form-inner-wrapper {
            height: calc(100% - 45px);
            overflow-y: auto;
            padding: 25px !important;
            .MuiFormControl-root {
                width: 100%;
                margin: 0 !important;
            }

            .welocome-msg {
                max-width: 100% !important;
                margin-bottom: 15px !important;
                font-size: 15px !important;
                // text-align: left !important;
                text-align: center !important;
                line-height: 20px !important;
                font-size: 15px !important;
                margin-bottom: 30px !important;
                font-weight: 400 !important;
            }
        }

        .form-inner-layout {
            // padding: 20px;
            // background-color: #F5F5F5;
            border-radius: 6px;
            // padding-bottom: 0 !important;
        }

        .welcome-text {
            font-size: 17px;
            font-weight: 500 !important;
            text-align: center;
            margin: 0 !important;
        }

        .welcome-subtext {
            margin: 0;
            // margin-top: 15px;
            margin-bottom: 15px;
            font-size: 16px;
        }

        .lets-start-wrapper {
            position: relative;
            padding: 15px;
            &::after {
                content: "";
                background: var(--header-bg-color) !important;
                width: calc(100% + 16px);
                height: 2px;
                position: absolute;
                top: 0;
                left: -8px;
                display: none !important;
            }
        }

        .has-floating-label {
            ::-webkit-input-placeholder {
                color: transparent !important;
            }

            .floating-group {
                position: relative;
            }

            .floating-label {
                position: absolute;
                top: 18px;
                transition: 0.2s all linear;
            }

            input {
                border: none !important;
                padding: 0 !important;
                padding-top: 21px !important;
                padding-bottom: 4px !important;
                border-bottom: 1px solid #ced4da !important;
                border-radius: 0 !important;
            }

            input:focus~.floating-label,
            input:not(:placeholder-shown)~.floating-label {
                top: 0;
            }

            .react-tel-input {
                border: none !important;
                border-bottom: 1px solid #ced4da !important;

                .selected-flag {
                    padding: 0 !important;
                }
            }
        }

        img {
            width: 100% !important;
            margin-bottom: 20px !important;
        }

        .loading-comp img {
            max-width: 150px;
        }

        .react-tel-input {
            border: 1px solid #ced4da !important;
            height: 35px !important;
            padding: 0.375rem 0.75rem !important;
            border-radius: 0.25rem !important;
        }

        .react-tel-input {
            height: auto !important;
            padding: 0 !important;

            .form-control {
                border: none !important;
                padding: 10px !important;
                padding-left: 50px !important;
            }

            .selected-flag {
                padding: 0 0 0 10px !important;
            }

            .search-box {
                padding: 3px 8px 5px !important;
                margin-left: 0;
            }

            .country-list {
                width: 270px !important;
            }
        }

        .form-group {
            .PhoneInput {
                .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
                    border: 0 !important;
                    padding: 0 !important;
                    width: 50px !important;
                }

                .MuiInputBase-root.MuiOutlinedInput-root.jss5.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
                    border: 0 !important;
                }

                MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {}

                .MuiInput-underline:before {
                    border: 0 !important;
                }
            }
        }
    }

    .wel-message {
        border: 1px solid #D6D6D6 !important;
        padding: 10px !important;
        box-shadow: 0px 2px 15px rgb(0 0 0 / 10%) !important;
        border-radius: 16px 16px 16px 0px !important;
    }

    .before-start-text {
        margin-bottom: 15px !important;
        white-space: break-spaces;

        p {
            margin-top: 15px !important;
            color: #000 !important;
            font-weight: 600 !important;
            font-size: 13px !important;
            margin-bottom: 5px !important;
        }

        small {
            font-size: 13px !important;
        }
    }

    .form-wrap {
        label {
            margin-bottom: 10px !important;
        }
    }

    .form-group {
        margin-bottom: 20px !important;

        label {
            margin-bottom: 5px !important;
            display: inline-block;
            font-weight: 500 !important;
            font-size: 15px;
        }
    }

    .star-rating {
        label {
            color: #bbb !important;
            margin-right: 5px !important;
            font-size: 24px !important;
        }
    }

    .asterisk {
        color: rgb(207, 21, 21) !important;
    }

    .pd-wrapper {
        border-radius: 16px 16px 16px 0px !important;
        background: #FFFFFF !important;
        border: 1px solid #E3E3E3 !important;
        box-sizing: border-box !important;
        padding: 10px !important;
        font-size: 14px !important;
        margin: 10px 0 !important;
        font-family: "Montserrat", sans-serif !important;

        label {
            margin-bottom: 5px !important;
            display: inline-flex !important;
        }

        .skip-link {
            display: inline-flex !important;
            align-items: center !important;
            margin-top: 7px !important;
            color: var(--primary-color) !important;
            cursor: pointer !important;

            svg {
                font-size: 20px !important;
            }
        }

        .edit-details {
            font-size: 20px !important;
            cursor: pointer !important;
            margin-top: 7px !important;
            display: block !important;
        }

        .text-danger {
            color: rgb(243, 29, 29) !important;
            font-size: 12px !important;
        }
    }

    .talkk-input-group {
        position: relative !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
        width: 100% !important;

        .form-control {
            position: relative !important;
            -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
            width: 100% !important;
            min-width: 0 !important;
            margin-bottom: 0 !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }

        .input-group-append {
            display: -ms-flexbox !important;
            display: flex !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            .btn {
                padding: 6px 10px !important;
            }

            svg {
                font-size: 16px !important;
                font-weight: bold !important;
            }
        }
    }

    .lets-start-wrapper {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;

        .lets-start {
            font-size: 14px !important;
        }

        .btn {
            padding: 5px 6px !important;
        }

        svg {
            font-size: 13px !important;
        }
    }
}

.talkk-web-chatbot-main-wrap {
    p.error {
        color: red !important;
        font-size: 14px !important;
        margin-top: 2px !important;
    }

    .react-tel-input {
        .special-label {
            display: none !important;
        }

        input {
            width: 100% !important;
            padding-left: 50px !important;
            border: 0 !important;

            &:focus-visible {
                border: 0 !important;
                outline: 0 !important;
            }
        }

        .selected-flag {
            padding: 0 !important;
        }
    }
}