.talkk-chat-wrapper-c {
    .mini-bot-close {
        position: fixed !important;
        bottom: 25px !important;
        right: 40px !important;
        // width: 60px;
        height: 60px !important;
        cursor: pointer !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 8px !important;
        animation: slide-in-from-bottom 0.2s ease-out !important;
        box-sizing: border-box !important;
        filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.24)) !important;
        overflow: visible !important;
        -webkit-tap-highlight-color: transparent !important;
        -webkit-touch-callout: none !important;
        -webkit-user-select: none !important;
        -khtml-user-select: none !important;
        -moz-user-select: none !important;
        -ms-user-select: none !important;
        user-select: none !important;
        border-radius: var(--chat-toggle-radius) !important;
        z-index: 999 !important;
        @media only screen and (max-width: 767px) {
            right: 25px !important;
        }

        // transition: transform 0.1s ease-out;
        .bot-img-wrapper {
            background: #fff !important;
            width: 60px !important;
            height: 60px !important;
            border: 2px solid var(--btn-bg) !important;
            border-radius: var(--chat-toggle-radius) !important;
            overflow: hidden;
        }

        img {
            width: 100% !important;
            border-radius: var(--chat-toggle-radius) !important;
        }

        .ripple-effects {
            width: 110px !important;
            height: 110px !important;
            bottom: -24px !important;
            right: -23px !important;
            position: absolute !important;
            z-index: -1 !important;
            background: none !important;

            &::after {
                content: '' !important;
                position: absolute !important;
                left: 50% !important;
                top: 50% !important;
                width: 10px !important;
                height: 10px !important;
                display: block !important;
                transform: translate(-50%, -50%) !important;
                background-color: #57BAFF !important;
                z-index: 0 !important;
                animation: animatepulseCorcle 4s infinite !important;
                border-radius: var(--chat-toggle-radius) !important;
            }
        }
    }

    .mini-bot-open {
        position: fixed !important;
        bottom: 25px !important;
        right: 40px !important;
        width: 60px !important;
        height: 60px !important;
        cursor: pointer !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: var(--chat-toggle-radius) !important;
        background-color: #fff !important;
        font-size: 20px !important;
        filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.24)) !important;
        box-sizing: border-box !important;
        animation: rotate-icon 0.2s ease-out !important;
        // transition: animation 0.3s linear !important;
        transition: 0.1s animation linear !important;

        @media only screen and (max-width: 767px) {
            right: 25px !important;
        }
    }

    @keyframes animatepulseCorcle {
        0% {
            opacity: .45 !important;
            width: 0 !important;
            height: 0 !important;
        }

        40% {
            opacity: .45 !important;
        }

        100% {
            opacity: 0 !important;
            width: 100% !important;
            height: 100% !important;
        }
    }

    @keyframes rotate-icon {
        0% {
            transform: rotate(90deg) !important;
        }

        100% {
            transform: rotate(180deg) !important;
        }
    }
}
.bg-webchatbot-overlay {
    position: fixed !important;
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    background-color: rgba(0, 0, 0, 0.6) !important;
    &.show{
        display: block !important;
    }
}
.talkk-web-chatbot-main-wrap{
    .floating-nibs{
        display: none;
    }
}
.floating-card {
    font-family: "Mulish" !important;
    background-color: #fff !important;
    padding: 3px !important;
    border-radius: 8px !important;
    color: var(--fcard-text) !important;
    position: fixed !important;
    right: 92px !important;
    bottom: 84px !important;
    max-width: 350px !important;
    min-width: 350px !important;
    cursor: pointer !important;
    // border: 1px solid #ccc !important;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922) !important;
    border-radius: 23px !important;

    .silder-sec-header {
        min-height: 70px;
        padding: 15px;
        background: var(--fcard-bg) !important;
        border-radius: 20px 20px 0 0;
        position: relative;
    }

    @media only screen and (max-width: 767px) {
        bottom: 90px !important;
        max-width: 70% !important;
        min-width: 70% !important;
        right: 50px !important;
    }

    // transition: 0.3s min-height linear !important;
    // animation: 3s slidein linear !important;
    svg {
        color: #000 !important;
    }

    &.hide-float {
        height: 0 !important;
        padding: 0 !important;
        overflow: hidden !important;
        min-height: 0 !important;
    }

    .close-float {
        position: absolute !important;
        right: -10px !important;
        top: -14px !important;
        background-color: #fff !important;
        border-radius: 50% !important;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
        padding: 3px !important;
        cursor: pointer !important;
    }

    h3 {
        margin-top: 0 !important;
        font-size: 17px !important;
        margin-bottom: 10px !important;
        line-height: normal !important;
        color: var(--fcard-text) !important;
        font-family: "Montserrat", sans-serif !important;
        position: absolute;
        bottom: 7px;

        @media only screen and (max-width: 767px) {
            font-size: 12px !important;
        }
    }

    .slider-count {
        font-size: 12px;
        margin-bottom: 6px;

        .slider-count-1 {
            font-size: 14px;
        }
    }

    p {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        padding-bottom: 10px !important;
        word-break: break-word !important;
        line-height: normal !important;
        color: var(--fcard-inner-text) !important;
        font-family:"Montserrat", sans-serif !important;
        border: 1px solid #939393;
        padding: 18px !important;
        border-radius: 10px;
        font-size: 15px;

        @media only screen and (max-width: 767px) {
            font-size: 10px !important;
        }
    }

    .slider-wrapper {
        background-color: var(--fcard-inner-bg) !important;
        border-radius: 6px !important;
        color: var(--fcard-inner-text) !important;
        padding: 20px 15px !important;
        float: left !important;
        padding-bottom: 513px !important;
        margin-bottom: -500px !important;
        width: 100% !important;
        position: relative !important;
        background-color: transparent !important;
    }

    .card-img {
        position: absolute !important;
        width: 35px !important;
        height: 35px !important;
        right: 10px !important;
        top: -14px !important;
        border-radius: 50% !important;
        background-color: #fff !important;
        padding: 4px !important;
        position: absolute !important;
        bottom: -20px !important;
        top: auto !important;
        z-index: 99;
        width: 45px !important;
        height: 45px !important;
    }

    .small-bot {
        position: absolute !important;
        right: 22px !important;
        bottom: 20px !important;
    }

    .small-bot img {
        max-width: 60px !important;
        background-color: #fff !important;
        border-radius: 50% !important;
        padding: 2px !important;
        filter: drop-shadow(0px 2px 16px rgba(0, 0, 0, 0.24)) !important;
        border: 1px solid var(--primary-color) !important;
    }

    &.language-floating-card {
        border-radius: 8px 8px 0 !important;
        max-width: 340px !important;
        min-width: 340px !important;

        @media only screen and (max-width: 767px) {
            bottom: 90px !important;
            max-width: 300px !important;
            min-width: 300px !important;
            right: 50px !important;
        }

        @media only screen and (max-width: 360px) {
            max-width: 270px !important;
            min-width: 270px !important;
        }

        .change-langage-block {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            margin-bottom: 0 !important;
            border-radius: 10px !important;
            background: var(--fcard-inner-bg) !important;
            padding: 10px !important;
        }

        .change-lang-para {
            margin: 0 !important;
            // margin-bottom: 20px !important;
            color: var(--fcard-text) !important;
            font-weight: 800 !important;
            padding-bottom: 15px !important;

            @media only screen and (max-width: 767px) {
                font-size: 15px !important;
            }
        }

        .language-block {
            background: var(--fcard-inner-bg) !important;
            color: var(--fcard-inner-text) !important;
            padding: 16px 8px !important;
            border-radius: 6px !important;
            margin: 5px 6px !important;
            font-size: 13px !important;
            min-width: 90px !important;
            text-align: center !important;
            border: 1px solid var(--fcard-inner-text) !important;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;

            &:hover {
                background-color: var(--fcard-bg) !important;
                color: var(--fcard-text) !important;
                border: 1px solid var(--fcard-bg) !important;
            }

            &:first-child {
                margin-left: 0 !important;
            }

            &:last-child {
                margin-right: 0 !important;
            }

            @media only screen and (max-width: 767px) {
                padding: 12px 12px !important;
                border-radius: 6px !important;
                margin: 5px 4px !important;
                font-size: 12px !important;
                min-width: 74px !important;
                min-height: 56px !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
            }

            @media only screen and (max-width: 360px) {
                padding: 9px 9px !important;
                border-radius: 6px !important;
                margin: 3px 3px !important;
                font-size: 12px !important;
                min-width: 70px !important;
            }
        }
    }
}

@keyframes slidein {
    0% {
        min-height: 0 !important;
        padding: 20px !important;
    }

    100% {
        min-height: 100px !important;
        padding: 20px !important;
    }
}