.talkktodoc-main-wrap {
    background-color: #f3f7fd;
    width: 100%;
    // height: calc(100vh - 59px);
    // @media only screen and (max-width : 767px) {
    //     height: auto !important;
    // }
    display: flex;

    .ttd-header {
        padding: 5px 15px;
        background-color: #fff;
        display: flex;
        justify-content: flex-end;
        min-height: 68px;

        .ttd-logo {
            max-width: 50px;
            height: auto;
            width: 100%;
            object-fit: contain;
        }
    }

    .ttd-chat-wrapper {
        width: 100%;
        // margin-top: 40px;
        // position: relative;
        // bottom: 0;
        @media only screen and (min-width: 1024px) {
            // margin-top: 40px;
            position: absolute;
            bottom: 0;
        }
    }

    .ttd-body {
        padding: 0;
        position: relative;
        @media only screen and (max-width : 767px) {
            padding: 0 !important;
        }
    }

    .chat-send-msg-wrapper,
    .chat-body-wrapper {
        background-color: #fff !important;
        position: unset !important;
    }

    .chat-body-wrapper {
        min-height: calc(var(--mobile-height) - 190px);
        max-height: calc(var(--mobile-height) - 190px);
        height: auto !important;
    }

    .chat-inner-content {
        // background-color: #fff !important;
        // min-height: calc(100vh - 108px);
        border-radius: 10px;
        @media only screen and (max-width : 767px) {
            min-height: calc(var(--mobile-height) - 110px);
        }
        .loading-comp {
            margin-top: 0 !important;
            max-height: none !important;
            z-index: 99 !important;
            background: rgba(0, 0, 0, 0.4) !important;
            border-radius: 0 !important;
        }

        .chat-inner-layout {
            max-width: calc(100% - 120px);
            margin: auto;
            padding-top: 41px;
            @media only screen and (max-width: 1023px) {
                padding-top: 0;
            }
        }
    }

    .typebar-fields {
        box-shadow: none !important;
        border-radius: 10px !important;
        border: 1px solid #939393 !important;
    }

    .conversation-parent-wrapper {
        background-color: #4CB185;
    }

    .has-pdf-drawer-open {
        position: relative;

        .chat-inner-content {
            max-width: 55%;
            @media only screen and (max-width: 767px) {
                max-width: 100% !important;
            }
        }
    }
    // .slick-slider .slick-track, .slick-slider .slick-list{
    //     height: auto !important;
    // }
    // .slick-vertical .slick-slide {
    //     min-height: calc(var(--mobile-height) - 210px);
    // }
    .mobile-message-wrapper{

        .slick-list{
            // margin-top: 24px;
        }
        .slick-arrow{
            top: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;
            right: 40px;
            bottom: 105px;
            position: fixed;
            width: 40px;
            height: 40px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(153, 153, 153, 0) 123.41%);
            border-radius: 59%;
            &.prev{
                bottom: 151px;
            }
        }
        .slick-arrow{
            
            &.slick-prev:before, &.slick-next:before{
                font-size: 40px;
                // color: #cecece;
                // opacity: 1;
            }
            &.slick-next{
                transform: rotate(90deg);
            }
            &.slick-prev{
                display: block;
                right: 37px;
                left: auto;
                bottom: 148px;
                transform: rotate(90deg);
            }
        }
        .slick-vertical .slick-slide {
            // min-height: calc(var(--mobile-height) - 245px);
            min-height: auto;
        }
    
        .slick-vertical .slick-slide {
            height: calc(var(--mobile-height) - 145px);
            // border: 1px solid rgba(255, 255, 255, 0.3);
            // box-shadow: 2px 2px 24px 2px rgba(255, 255, 255, 0.1) inset;
            // border: 1px solid rgba(255, 255, 255, 0.2);
            // border-radius: 60px;
            background: url('../../../assets/images/chat-layer.png');
            background-size: cover;
            padding: 0 15px 0 0;
            border-radius: 60px;
            background-position: top;
            // background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0.02%, rgba(153, 153, 153, 0) 180%);
            // box-shadow: -8px 6px 34px -3px rgba(255, 255, 255, 0.1) inset;
            // clip-path: polygon(0% 0%, 0% 103%, 100% 100%, 100% 0%, 63% 0%, 63% 1%, 61% 4%, 59% 5%, 58% 6%,54% 7%, 47% 7%, 44% 6%, 42% 5%, 40% 3%, 39% 1%, 39% 0%);
        }
    }

    .ttd-new-message-wrapper{
        position: relative !important;
    }
    .esoft-feedback-buttons2{
        position: absolute;
        bottom: -21px;
        left: 50%;
        transform: translateX(-41%);
        margin: 0;
        padding-left: 0;
    }
    .document-wrapper.pdf-doc-wraper{
        max-width: 300px !important;
    }
    .ttd-new-message-wrapper {
        // border-radius: 4px;
        // padding: 20px;
        // margin-bottom: 20px;
        // border: 1px solid #9393934D;
        margin: 0 !important;
        width: 100%;
        display: inline-block;
        .negative-rate-wrapper {
            background-color: #000 !important;
        }
        .negative-rate-wrapper .negative-list li {
            font-size: 12px !important;
            &:hover{
                background-color: transparent;
            }
        }
        // .user-msg-wrapper {
        //     padding-top: 20px !important;
        // }
        @media only screen and (max-width : 767px) {
            // margin: 20px 10px !important;
        }

        .ttd-message-body{
            height: calc(var(--mobile-height) - 313px); 
            overflow-y: auto;
            margin-bottom: 40px !important;
            margin-top: 10px;      
        }
        .message-block.has-pdf-docs .pdf-bottom-info-wrapper {
            margin-left: 15px !important;
            @media only screen and (max-width : 767px) {
                margin-left: 0 !important;
            }
        }

        .ttd-message-body {
            // border-top: 1px solid #ccc;
            // padding-top: 10px;
            // margin-top: 20px;
            // position: relative;
        }
        &.hide-user-message{
            .ttd-message-headning {
                display: none;
            }
            .ttd-message-body{
                border-top: none;
                padding-top: 0;
                margin-top: 0;
            }
        }

        .ttd-message-headning {
            position: relative;
            .date-wrapper {
                display: none;
                // font-size: 11px;
                // color: #939393;
                // position: absolute;
                // right: 0;
                // bottom: -14px;
            }
        }

        .footer-icons-wrapper {
            text-align: right;
            // margin-top: -17px;
            margin-bottom: -9px;
            .ttd{
                font-size: 18px;
                margin-left: 15px;
                cursor: pointer;
            }
            svg {
                font-size: 20px;
                margin-left: 10px;
                cursor: pointer;
            }

            .positive-rating::before{
                color: #3EB655;
            }

            .negative-rating::before{
                color: #D50001;
            }
        }

        .user-msg-wrapper,
        .bot-msg-wrapper {
            .message-wrapper {
                width: 100% !important;
                margin-right: 0 !important;

                .message-block {
                    width: 100% !important;
                    max-width: none !important;
                    background-color: transparent !important;
                    border: none !important;
                    padding: 0 !important;
                    // color: #000 !important;
                    margin-bottom: 5px !important;
                }
            }
        }

        .message-loaders {
            width: 100%;
            min-height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                max-width: 250px;
            }
        }

        .user-msg-wrapper {
            font-size: 16px !important;
            font-weight: 600;
            margin-top: 0 !important;
            padding-top: 30px !important;
            @media only screen and (max-width: 1023px) {
                padding-top: 45px !important;
            }
        }

        .msg-parent-wrapper {
            margin-left: 0 !important;
            max-width: none !important;
            width: 100% !important;
        }

        .attachment-wrapper {
            max-width: 70% !important;
            margin-bottom: 15px !important;
        }
        .tlk-attachment-wrapper{
            width: 100%;
            max-width: unset !important;
        }
    }

    .no-chat-wrapper {
        .footer-wrapper {
            &::after {
                display: none;
            }
        }
    }

    .no-chat-wrapper {
        text-align: center;
        padding: 35px 0;
        .no-chat-heading{
            font-size: 38px !important;
        }
        .hello-text {
            font-size: 21px;
            margin: 10px 0 6px 0;
            color: #939393;
            font-weight: 600;
        }

        .bot-profile-img {
            width: 75px;
            border-radius: 50%;
            height: 75px;
            object-fit: contain;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922) !important;
            padding: 2px;
        }

        h6 {
            margin: 0;
            font-size: 18px;
            margin-bottom: 10px;
        }

        .popular-search-wrapper {
            border: 1px solid #9393934D;
            padding: 20px;
            border-radius: 4px;
            margin: 0 15px;
            margin-top: 22px;
            padding-bottom: 0;
            position: relative;
            padding-top: 30px !important;
            .suggetion-sub-head{
                text-align: left;
                font-size: 14px;
                margin-bottom: 12px;
                color: var(--btn-bg);
                font-weight: 600;
            }
            h6 {
                display: flex;
                align-items: center;
                justify-content: left;
                font-size: 15px;
                position: absolute;
                top: -20px;
                padding: 10px 13px;
                border: 1px solid #939393;
                border-radius: 9px;
                left: 50%;
                transform: translateX(-50%);
                color: #13517E;
                background-color: #fff;
                @media only screen and (max-width: 767px) {
                    font-size: 13px !important;
                }
                img {
                    margin-right: 10px;
                    display: none;
                }
            }
            .trending-qna-wrap .suggetion-sub-head{
                padding-top: 15px;
                margin-top: 5px;
                border-top: 1px solid rgba(147, 147, 147, 0.3);
            }
        }

        .popular-message-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
        }

        .popular-message-item {
            width: 47%;
            background-color: #F3F7FD;
            padding: 10px;
            text-align: left;
            color: #5F5F5F;
            margin-right: 15px;
            margin-bottom: 15px;
            border-radius: 7px;
            font-size: 12px;
            cursor: pointer;
            border: 1px solid #13517E4D;
            color: rgba(95, 95, 95, 1) !important;
            font-weight: 400 !important;
            padding: 13px 10px;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
        @media only screen and (min-width: 1200px) and (max-width : 1400px) {
            padding: 25px 0 !important;
            .no-chat-heading{
                font-size: 30px !important;
                margin: 10px 0 !important;
            }
            .popluar-srch-m-wrap {
                max-height: calc(100vh - 311px) !important;
            }
            .popular-search-wrapper {
                margin-top: 4px !important;
                padding-top: 15px !important;
                .suggetion-sub-head{
                    font-size: 13px !important;
                }
            }
            .popular-message-item {
                font-size: 12px !important;
            }
        }
        @media only screen and (max-width: 767px) {
            .no-chat-heading{
                font-size: 25px !important;
                word-break: break-all !important;
            }
            .popular-message-item {
                font-size: 13px !important;
            }        
            .suggetion-sub-head{
                font-size: 15px !important;
            }
        }
    }
    .no-chat-wrapper{
        .typebar-fields .form-control {
            height: 45px !important;
            font-size: 18px !important;
            padding-right: 100px !important;
            line-height: 33px !important;
        }
        .chat-send-msg-wrapper .input-fields i {
            font-size: 25px !important;
            right: 50px !important;
        }
        .typebar-fields.only-typebar-field .send-icon-wrap {
            // width: 28px !important;
            // height: 28px !important;
            // left: 6px;
        }
    }
        
    .user-msg-wrapper .message-timing, .user-msg-wrapper .message-agent{
        display: none !important;
    }
    .bot-msg-wrapper .message-timing, .bot-msg-wrapper .message-agent{
        display: none !important;
    }
    .pdf-sideview-wrapper{
        position: absolute !important;
        top: 19px !important;
        width: 0 !important;
        overflow-x: hidden;
        background-color: #fff;
        z-index: 9999999 !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
        right: 11px !important;
        overflow: hidden;
        transition: none !important;
        height: 100% !important;
        .pdf-wrap-header{
            padding: 15px 30px !important;
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            min-height: 74px !important;
            position: sticky;
            top: 0;
            z-index: 9;
            background-color: rgba(37, 88, 174, 1);
            border: none !important;
            h6{
                font-size: 18px !important;
                font-weight: 500 !important;
                margin: 0 !important;
            }
        }
        canvas{
            width: 100% !important;
            height: auto !important;
            max-width: 800px;
            margin: auto;
        }
        .zoom-btn{
            cursor: pointer;
            color: #fff;
            margin-left: 10px;
        }
        .pdf-wrap-body{
            padding: 15px !important;
            height: calc(100vh - 75px) !important;
            overflow-y: auto !important;
            width: 100%;
            // overflow-x: hidden;
            @media only screen and (max-width: 767px) {
                height: calc(100vh - 74px) !important;
            }
            &.has-pdf-doc{
                overflow-x: auto;
            }
            iframe{
                height: calc(100vh - 108px);
            }
            .closebar-toggler{
                position: absolute !important;
                left: -4px;
                z-index: 99;
                max-width: 29px;
                cursor: pointer;
                display: none !important;
            }
        }
        .close-pdf-icon{
            // position: fixed !important;
            // right: 20px !important;
            // top: 20px !important;
            // z-index: 999999 !important;
            font-size: 28px !important;
            color: var(--btn-bg) !important;
            cursor: pointer !important;
        }
        &.show{
            // right: 11px !important;
            position: fixed !important;
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            background-color: #000;
            @media only screen and (max-width: 767px) {
                width: 100% !important;
            }
        }
        @media only screen and (max-width : 767px) {
            position: fixed !important;
            top: 0 !important;
            right: 0 !important;
            height: 100vh !important;
            &.show{
                right: 0 !important;
            }
        }
        // .react-pdf__Page{
        //     overflow: auto !important;
        // }
        canvas{
            // width: 100% !important;
            // height: auto !important;
            // width: auto !important;
            // height: auto !important;
        }
    }
    .bot-msg-wrapper .bot-msg-btn-wrapper .btn-inlines-outline {
        width: auto !important;
        border-radius: 25px !important;
        min-width: 150px !important;
        max-width: 375px !important;
        padding: 12px 20px !important;
        font-weight: 300 !important;
    }
    .welcome-wrapper .form-inner-wrapper {
        padding: 0 !important;
    }
    .no-chat-wrapper {
        margin-top: -100px;
        text-align: center;
        padding: 35px 0;
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        max-width: 800px;
        transform: translateX(-50%);
        max-height: calc(100vh - 105px);
        padding-bottom: 0;
        overflow-y: auto;
        .chat-send-msg-wrapper::after {
            display: none;
        }
        .chat-send-msg-wrapper {
            position: unset !important;
        }
        @media only screen and (max-width: 1023px) {
            max-height: none;
        }
        .popluar-srch-m-wrap{
            max-height: calc(100vh - 319px);
            @media only screen and (max-width: 767px) {
                max-height: calc(var(--mobile-height) - 290px);
            }
            overflow-y: auto;
        }
        .popular-search-wrapper {
            border-radius: 7px;
        }
        .popular-message-item {
            color: #000 !important;
            font-weight: 400 !important;
        }
        .popular-serach-img{
            font-size: 18px;
            &.active-serch::before{
                color: var(--btn-bg) !important;
            }
        }
        .newchat-section-items{
            width: 100% !important;
            h4{
                font-size: 18px;
                margin: 0 !important;
                font-weight: 500 !important;
            }
            ul li {
                padding-top: 13px !important;
                font-size: 14px;
                &:last-child{
                    border-bottom: none;
                }
            }
            .trending-qna-list{
                cursor: pointer !important;
            }
        }
    }
    .pouplar-srch-overlay{
        position: relative;
        width: 100%;
        .popular-bg-overlay{
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.2);
            top: 0;
            left: 0;
        }
        .popular-search-wrapper{
            position: absolute !important;
            bottom: 4px;
            z-index: 999 !important;
            width: 94%;
            background-color: #fff;
            margin: 0 !important;
            margin-left: 20px !important;
            overflow: hidden;
            transition: 0.3s height linear;
            padding: 0 !important;
            border: none;
            @media only screen and (max-width : 767px) {
                width: 88% !important;
            }
            &.hide-search-wrap{
                width: 0 !important;
                overflow: hidden;
                padding: 0 !important;
                border: none;
            }
            // &.show-populer-search{
            //     padding: 20px;   
            // }
        }
        .popular-search-wrapper {
            border-radius: 7px;
        }
        .popular-message-item {
            color: #000 !important;
            font-weight: 400 !important;
        }
        .popular-serach-img{
            font-size: 18px;
            &.active-serch::before{
                color: var(--btn-bg) !important;
            }
        }
        .popular-scrollable{
            max-height: calc(100vh - 315px);
            overflow-y: auto;
            overflow-x: hidden;
        }
        .popular-search-wrapper {
            // border: 1px solid #9393934D;
            // padding: 20px;
            border-radius: 4px;
            margin: 0 15px;
            margin-top: 22px;
            padding-bottom: 0;
            position: relative;
            .popular-scrollable{
                padding: 20px;   
                padding-right: 10px;
                border: 1px solid #9393934D;
            }
            .suggetion-sub-head{
                text-align: left;
                font-size: 14px;
                margin-bottom: 12px;
                color: var(--btn-bg);
                font-weight: 600;
            }
            h6 {
                display: flex;
                align-items: center;
                justify-content: left;
                font-size: 14px;
                position: absolute;
                top: -51px;
                padding: 10px 13px;
                border: 1px solid #939393;
                border-radius: 9px;
                left: 50%;
                transform: translateX(-50%);
                color: #13517E;
                background-color: #fff;
                @media only screen and (max-width: 767px) {
                    font-size: 13px !important;
                }
                img {
                    margin-right: 10px;
                    display: none;
                }
            }
            .trending-qna-wrap .suggetion-sub-head{
                padding-top: 15px;
                margin-top: 5px;
                border-top: 1px solid rgba(147, 147, 147, 0.3);
            }
        }

        .popular-message-wrapper {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
        }

        .popular-message-item {
            width: 47%;
            background-color: #F3F7FD;
            padding: 10px;
            text-align: left;
            color: #5F5F5F;
            margin-right: 15px;
            margin-bottom: 15px;
            border-radius: 7px;
            font-size: 13px;
            cursor: pointer;
            border: 1px solid #13517E4D;
            color: rgba(95, 95, 95, 1) !important;
            font-weight: 400 !important;
            padding: 13px 10px;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
    .has-popular-serch-item{

    }
    .bot-msg-wrapper .bot-msg-btn-wrapper.inline-btn-wrapper {
        margin-bottom: -5px !important;
    }
    .bot-msg-wrapper .bot-msg-btn-wrapper.suggest-btn-wrapper {
        padding-left: 21px !important
    }
    .ttd-new-message-wrapper {
        .slick-list{
            height: calc(var(--mobile-height) - 242px) !important;
        }
        .bot-msg-wrapper .bot-msg-btn-wrapper.suggest-btn-wrapper {
            padding-left: 56px !important;
        }
        .bot-msg-wrapper{
            .bot-img {
                display: block !important;
                left: 18px;
            }
            .message-wrapper {
                margin-left: 59px !important;
                width: auto !important;
                margin-bottom: 14px !important;
            }
        } 
        .bot-msg-wrapper .bot-msg-btn-wrapper .btn-inlines-outline {
            font-size: 13px !important;
            padding: 10px 19px !important;
            min-width: 80px !important;
            font-weight: 300 !important;
        }
        .bot-msg-wrapper .bot-msg-btn-wrapper .btn-inlines-outline, 
        .bot-msg-wrapper .suggest-btn-wrapper .btn-inlines-outline{
            font-size: 13px !important;
            padding: 10px 19px !important;
            min-width: 80px !important;
            font-weight: 300 !important;
            margin-top: 0 !important;
            margin-bottom: 10px !important;
        }
    }

    .theme-default .typebar-fields{
        border: none !important;
    }
    .drawer-overlay {
        position: fixed !important;
        top:0 !important;
        border-radius: 0 !important;
    }
    .leave-message-wrapper{
        .drawer-header {
            margin-top: 0 !important;
            padding: 20px !important;
            padding-top: 5px !important;
        }
        .drawer-body {
            height: calc(100% - 64px) !important;
            padding-top: 1px;
            max-height: calc(var(--mobile-height) - 347px) !important;
            min-height: 300px;
            // @media only screen and (max-width: 767px) {
            //     height: calc(100% - 65px) !important;
            // }
        }
    }
    .no-chat-wrapper .typebar-fields .form-control {
        line-height: 21px !important;
    }
    .voice-chat-wrapper .user-msg-wrapper .message-block {
        text-align: left !important;
    }
    .leave-message-wrapper.show-leave-form {
        height: 100vh !important;
        position: fixed !important;
    }
    .footer-icons-wrapper{
        position: relative !important;
    }
    .negative-rate-wrapper{
        position: absolute !important;
        bottom: 34px;
        // top: 22px !important;
        right: 0;
        box-shadow: 12px 13px 31px 5px rgba(255, 255, 255, 0.1) inset;
        background: rgba(0,0,0,0.5) !important;
        border: 1px solid rgba(255, 255, 255, 0.12);
        height: 0;
        overflow: hidden;
        display: none;
        .negative-list{
            padding-left: 0;
            margin: 0;
            li{
                padding: 10px;
                cursor: pointer;
                border-radius: 4px;
                list-style: none;
                &:hover{
                    background-color: #fff;
                    color: #fff;
                }
            }
        }
        .drawer-header {
            color: #fff !important;
        }
        .radio-list-wrap{
            color: #fff;
        }
        &.show-leave-form{
            display: block;
            padding: 12px;
            height: auto !important;
        }
        .closebar-toggler{
            color: #fff !important;
            display: block !important;
        }
    }
    .thank-you-msg-wrapper{
        height: calc(93vh - 200px) !important;
        background-color: #fff !important;
    }
    .welcome-wrapper{
        position: unset !important;
        width: calc(100% - 20px) !important;
        margin: 10px !important;
        height: calc(100vh - 141px) !important;
        @media only screen and (max-width: 767px) {
            // height: calc(100vh - 123px) !important;
            height: calc(var(--mobile-height) - 124px) !important;
        }
    }
    .feedback-wraper .form-inner-wrapper {
        max-height: calc(100% - 86px);
        min-height: calc(100% - 86px);
        @media only screen and (max-width: 767px) {
            max-height: calc(100% - 53px);
            min-height: calc(100% - 53px);  
        }
    }
    .chat-body-wrapper {
        min-height: calc(100vh - 103px);
        max-height: calc(100vh - 103px);
        @media only screen and (max-width: 767px) {
            min-height: calc(var(--mobile-height) - 90px);
            max-height: calc(var(--mobile-height) - 90px);
        }
    }
    .chatwidget-wrapper{
        padding: 0 !important;
    }
    .chat-body-wrapper.hide-typebar {
        height: calc(100vh - 103px) !important;
    }
    .chat-body-wrapper.has-chat-screen {
        min-height: calc(var(--mobile-height) - 270px);
        max-height: calc(var(--mobile-height) - 270px);
        @media only screen and (max-width: 1023px) {
            margin-top: 40px;
            min-height: calc(var(--mobile-height) - 175px);
            max-height: calc(var(--mobile-height) - 175px);  
        }
        @media only screen and (max-width: 767px) {
            margin-top: 0;
            min-height: auto;
            max-height: none;  
        }
    }
    .leave-message-wrapper{
        .closebar-toggler{
            display: none;
            height: auto !important;
            max-width: 27px !important;
            position: absolute;
            left: -6px;
            top: 3px;
            // @media only screen and (max-width: 767px) {
            //     display: block;
            // }
        }
    }
    .leave-message-wrapper, .custom-full-width-bot .leave-message-wrapper{
        width: 95% !important;
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        border-radius: 8px !important;
        .leavemsg-inner-forn{
            max-height: calc(100vh - 259px);
            overflow-y: auto;
        }
        &.show-leave-form {
            height: fit-content !important;
            display: block !important;
        }
    }

    .leave-message-wrapper{
        .closebar-toggler{
            cursor: pointer !important;
            display: block !important;
            color: #878787;
            font-size: 27px !important;
            max-width: none !important;
            right: 7px;
            position: absolute !important;
            left: auto;
            top: 8px;
        }
    }
    .header-wave {
        display: none !important;
    }
    .voice-chat-wrapper{
        .chat-send-msg-wrapper{
            margin: 0 !important;
            bottom: -4px !important;
            width: 100% !important;
            padding: 20px;
            .voice-footer {
                padding: 0 !important;
                min-height: 59px !important;
            }
        }
        .voice-wave {
            max-width: 46px !important;
        }
        .mobile-message-wrapper .slick-vertical .slick-slide {
            height: calc(var(--mobile-height) - 201px);
        }
        .ttd-new-message-wrapper .ttd-message-body {
            height: calc(var(--mobile-height) - 361px);
            overflow-y: auto;
            margin-bottom: 45px !important;
        }
        .chat-body-wrapper.has-chat-screen {
            height: auto !important;
        }
        .mobile-message-wrapper .slick-arrow.next {
            bottom: 160px;
        }
        .mobile-message-wrapper .slick-arrow.prev {
            bottom: 205px;
        }
    }
}
.has-full-bscreen{
    .talkktodoc-main-wrap {
        display: none !important;
    }
}
.talkktodoc-main-wrap{
    .has-leavemessage-form {
        .chat-inner-content {
            max-width: 60% !important;
        }
    
        .leave-message-wrapper {
            max-width: 36% !important;
            right: 24px !important;
        }
    }
    .newchat-second-suggestion {
        display: flex;
        // gap: 10px;
        width: 100%;
    
        .newchat-section-items {
            text-align: left;
            width: 50%;
            border: 1px solid #9393934D;
            padding: 20px;
            border-radius: 4px;
            margin: 0 15px;
            margin-top: 22px;
            padding-bottom: 0;
            position: relative;
            padding-top: 30px !important;
    
            h4 {
                font-size: 18px;
            }
    
            ul {
                padding-left: 15px;
    
                li {
                    padding-top: 25px;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #ddd;
                }
            }
    
        }
    }
    
    // Chat-History
    
    .chat-inner-layout-w-ch {
        .active-chat-history {
            display: flex;
            width: 100%;
            min-height: calc(100vh - 108px);
    
            .chat-history-wrapper {
                width: 35%;
                border-right: 2px solid #ddd;
    
                .chat-history-iwrp {
                    height: 100%;
                    padding: 20px 10px;
                }
            }
    
            .chat-body-main-wrapper {
                width: 65%;
                padding: 0 10px;
            }
        }
    }
    
    .chat-history-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #ddd;
        padding-bottom: 5px;
        .ch-header-actions {
            display: flex;
            gap: 10px;
        }
    
    }
    .chat-history-data {
        h6 {
            margin-bottom: 14px;
        }
        border-bottom: 1px solid #000;
        .chat-history-data-items {
            padding: 20px 10px 10px 10px;
            .ch-data-items-time-socials {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
    
    
    
    // My Feeds CSS
    
    .myfeeds-wrapper {
        width: 100%;
    
        .myfeeds-list {
            padding: 20px 0;
            border-bottom: 1px solid #ddd;
    
            .mf-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
    
                .mf-top1 {
                    display: flex;
                    align-items: center;
                    gap: 20px;
    
                    img {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }
    
                    .mf-top-inner-1 {
                        margin-top: 20px;
                    }
                }
            }
    
            .mf-bottom {
                img {
                    width: 100%;
                    display: block;
                    margin: 10px 0;
                }
            }
        }
    
    }
    .stack-card-container{
        width: 500px;
        height: 500px;
        overflow: hidden;
        position: relative;
        .ttd-new-message-wrapper{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            color: #fff;
            transition: transform 0.5s ease-in-out; 
        }
    }
}
