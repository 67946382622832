.talkk-chat-wrapper-c {
    .chat-send-msg-wrapper {
        position: absolute !important;
        bottom: 27px !important;
        left: 0 !important;
        // width: calc(100% - 150px) !important;
        background-color: transparent !important;
        margin: 0 auto !important;

        &::after {
            content: '';
            background: var(--header-bg-color) !important;
            width: 100%;
            height: 2px;
            position: absolute;
            top: 0;
            left: 0;
            display: none !important;
        }

        .footer-wrapper {
            // padding: 15px !important;
            padding: 0 !important;
            margin-top: 0;
            border: none;
            // padding-bottom: 8px !important;

            &.hidden-footer-wrapper {
                display: none;
            }
        }

        .voice-footer {
            padding: 5px 0 !important;
            min-height: 81px !important;
            position: relative !important;
            text-align: center;
        }

        .input-fields {
            position: relative !important;

            textarea {
                margin-right: 0 !important;
                height: 30px !important;
                resize: none !important;
                width: 100% !important;
                padding-right: 0 !important;
                padding-top: 7px !important;
                margin-bottom: 0 !important;
            }

            .form-control {
                min-height: 35px !important;
            }

            i {
                position: absolute !important;
                top: 50% !important;
                transform: translateY(-50%) !important;
                font-size: 20px !important;
                cursor: pointer !important;
                right: 30px !important;
                color: var(--btn-bg) !important;
            }
        }
    }
    .chat-footer-wrapper {
        position: absolute !important;
        bottom: 2px !important;
        width: auto !important;
        text-align: center !important;
        left: 0 !important;
        right: 0 !important;
        padding: 6px !important;
        font-size: 8px !important;
        border-bottom-left-radius: var(--chat-border-radius) !important;
        border-bottom-right-radius: var(--chat-border-radius) !important;
        // background-color: #fff;
        border-top: none !important;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 55px !important;
        }

        .chatgpt-logo {
            width: 25px;
            margin-left: 5px;
        }

        .company-text {
            font-weight: bold !important;
            text-decoration: none !important;
            color: #000000 !important;
        }

        .about-info-icon {
            font-size: 13px;
            cursor: pointer;
            margin: 0 0px 0 2px;
        }
    }
    .menu-icon-wrap{
        position: absolute !important;
        left: 7px;
        background: linear-gradient(180deg, rgba(255,255,255,0.2) 0%, rgba(153, 153, 153, 0.2) 123.41%);
        border-radius: 50%;
        padding: 6px;
        font-size: 38px;
        cursor: pointer;
    }

    .has-voice-chat {
        .voice-text-wrapper {
            text-align: center !important;
            font-size: 14px !important;
            padding: 5px !important;
            padding-top: 10px !important;
            padding-bottom: 20px !important;
        }
        .tap-speak-text{
            position: absolute;
            left: 50%;
            bottom: 65px;
            transform: translateX(-50%);
        }

        .voice-record-wrapper {
            // border-top: 1px solid #ccc;
            margin-top: 35px;
            text-align: center;

            &.block-mic svg {
                cursor: not-allowed !important;
            }

            .mute-icon {
                cursor: pointer !important;
                position: absolute !important;
                left: 18px;
                border: 1px solid #ccc;
                width: 32px;
                height: 32px;
                color: #686565;
                border-radius: 50%;
                padding: 3px;
                top: 24px;
                z-index: 9;
                background: #F9F9F9;

                &.muted {
                    background-color: var(--btn-bg) !important;
                    color: var(--btn-color) !important;
                }
            }

            .voice-wave {
                max-width: 80px !important;
                margin: 0 auto !important;
            }

            .mic-icon {
                width: 60px;
                height: 60px;
                color: #fff !important;
                margin: 0 30px !important;
                cursor: pointer !important;
                position: absolute !important;
                top: 6px !important;
                left: 50% !important;
                transform: translateX(-50%) !important;
                margin: 0 !important;
                // @media only screen and (max-width: 1023px) {
                    
                // }
            }
            svg {
                cursor: pointer !important;
            }

            .keyboard-icon {
                cursor: pointer !important;
                position: absolute !important;
                right: 20px !important;
                width: 24px !important;
                top: 24px !important;
            }
        }

        .voice-wave {
            max-width: 80px !important;
        }

        .mic-icon-wrapper {
            background-color: transparent !important;
            width: 25px !important;
            height: 25px !important;
            border-radius: 50% !important;
            padding: 7px !important;
            cursor: pointer !important;
            color: var(--btn-color) !important;
            position: relative;
            left: 0;
        }

        .typebar-fields {
            display: flex !important;
            align-items: center !important;
            background-color: #fff !important;
            box-shadow: none !important;
            border-radius: 10px !important;
            .mic-icon-wrapper {
                margin-left: 10px;
            }
            .form-control{
                height: 37px !important;
                border: 1px solid #ccc !important;
                padding: 7px 10px !important;
            }

            &.only-typebar-field {
                .send-icon-wrap {
                    // margin-right: -21px;
                }

                .mic-icon-wrapper {
                    background-color: transparent !important;
                    width: 25px !important;
                    height: 25px !important;
                    border-radius: 50% !important;
                    padding: 4px !important;
                    cursor: pointer !important;
                    color: var(--btn-color) !important;
                }

                .send-icon-wrap {
                    width: 28px !important;
                    height: 28px !important;
                    // padding: 10px !important;
                    // margin-right: 0 !important;
                    position: unset !important;
                    transform: none !important;
                }
            }

            textarea {
                border: none !important;
                box-shadow: none !important;
                &::placeholder {
                    color: #ccc !important;
                    opacity: 1; /* Firefox */
                }
                  
                &::-ms-input-placeholder { /* Edge 12 -18 */
                    color: #ccc !important;
                }
                &:focus {
                    box-shadow: none !important;
                }
            }
        }
    }
    .has-popular-serach{
        .form-control{
            padding-right: 61px !important;
        }
        .popular-serach-img{
            position: absolute;
            right: 29px;
            max-width: 20px;
            cursor: pointer;
        }
    }
    &.theme-vintage{
        .chat-send-msg-wrapper {
            &::after {
                display: block;
            }
        }
        .typebar-fields {
            margin-right: 18px !important;
            padding: 4px !important;
            border: 1px solid #93939380 !important;
            .form-control{
                border: none !important;
                padding-right: 98px !important;
                @media only screen and (max-width:1023px) {
                    padding-right: 34px !important;
                    padding-left: 42px !important;
                }
            }
            &.only-typebar-field {
                .send-icon-wrap {
                    position: absolute !important;
                    background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(153, 153, 153, 0.2) 123.41%);
                    padding: 8px !important;
                    left: auto;
                    cursor: pointer;
                    right: 158px;
                    width: 38px !important;
                    height: 38px !important;
                    // right: -19px !important;
                    @media only screen and (max-width : 1023px) {
                        right: -60px;
                        width: 49px !important;
                        height: 49px !important;
                        padding: 12px !important;
                    }
                }
                .ttd{
                    transform: translateY(-50%) !important;
                }
            }
            .popular-serach-img.active-serch::before{
                color: var(--btn-bg) !important;
            }
        }
    }

    @media only screen and (max-width: 767px) {
        .desktop-bot .chat-footer-wrapper {
            border-radius: 0 !important;
        }
    }

    .mobilink-wrapper .chat-footer-wrapper {
        border-radius: 0 !important;
    }
}