.talkk-web-chatbot-main-wrap{
    .theme-default{
        .bot-msg-wrapper .bot-msg-btn-wrapper .btn-inlines-outline {
            max-width: max-content !important;
            min-width: 100px !important;
            border-radius: 28px !important;
            padding: 5px 9px !important;
            font-size: 12px !important;
        } 
        .message-block {
            padding: 15px !important;
            padding-bottom: 20px !important;
            padding-top: 15px !important;
        }
        .chat-header-wrapper h4 {
            font-size: 13px !important;
        }  
        .chat-body-wrapper.has-chat-screen {
            height: calc(100% - 155px) !important;
        }
        .chat-body-wrapper.has-no-footer {
            height: calc(100% - 88px) !important;
        }
        .chat-send-msg-wrapper .footer-wrapper {
            padding: 5px 15px !important;
            margin-top: 0;
            border: none;
            padding-bottom: 1px !important;
        }
        .chat-send-msg-wrapper .input-fields i {
            right: 64px !important;
        }
    }
}