.talkk-chat-wrapper-c {
    .chat-header-wrapper {
        height: auto !important;
        width: auto !important;
        background: var(--header-bg-color) !important;
        display: flex !important;
        align-items: center !important;
        padding: 15px !important;
        color: var(--header-color) !important;
        border-top-left-radius: var(--chat-border-radius) !important;
        border-top-right-radius: var(--chat-border-radius) !important;
        position: relative !important;
        min-height: 58px !important;
        img {
            width: 30px !important;
            height: 30px !important;
            border-radius: 50% !important;
            background: #fff !important;
            padding: 2px !important;
        }

        h4 {
            margin: 0 !important;
            margin-left: 10px !important;
            color: var(--header-color) !important;
            display: flex !important;
            align-items: center !important;
            font-size: 15px !important;
            .about-info-icon {
                font-size: 19px !important;
                cursor: pointer !important;
                margin-left: 4px !important;
            }
        }

        .menu-icon {
            cursor: pointer !important;
        }
        .header-wave{
            display: none;
            svg{
                position: absolute !important;
                bottom: -18px;
                left: 0;
                z-index: 999;
                width: 100%
            }
        }
        .gems-h-logo{
            margin-right: 10px;
        }
    }

    .close-small-bot {
        display: none !important;
        margin-left: 20px !important;
        cursor: pointer !important;
    }
    &.theme-vintage{
        .chat-header-wrapper{
            min-height: 70px !important;
            img {
                position: absolute !important;
                bottom: -22px !important;
                left: 50% !important;
                transform: translateX(-50%) !important;
                box-shadow: 0px 4px 4px 0px #00000040 !important;
                z-index: 1000 !important;
                width: 50px !important;
                height: 50px !important;
            }
            h4 {
                position: absolute;
                bottom: 9px;
                left: 0;
                max-width: 115px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .gems-h-logo{
                top: 10px !important;
                width: 32px !important;
                height: 32px !important;
                left: 24px !important;
            }
        }
    }

    .bot-name-info-wrapper {
        max-width: 80% !important;
    }

    @media only screen and (max-width: 767px) {
        .desktop-bot .chat-header-wrapper {
            border-radius: 0 !important;

            .bot-name-info-wrapper {
                max-width: 70% !important;
            }

            .close-small-bot {
                display: block !important;
            }

        }
    }

    .dd-overlay {
        position: fixed !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        width: 100% !important;
        height: 100% !important;
        /* background: red; */
        z-index: 999 !important;
    }

    .mobilink-wrapper {
        .chat-header-wrapper {
            border-radius: 0 !important;
        }
    }

    .header-dropdown {
        position: relative !important;

        .icon-menu {
            color: var(--header-color) !important;
        }

        .dropdown-menu {
            position: absolute !important;
            top: 21px !important;
            z-index: 1000 !important;
            right: 2px !important;
            left: auto !important;
            border-radius: 0 !important;
            display: none !important;
            min-width: 150px !important;
            padding: 0 !important;
            margin: 0.125rem 0 0 !important;
            font-size: 14px !important;
            color: #212529 !important;
            text-align: left !important;
            list-style: none !important;
            background-color: #fff !important;
            background-clip: padding-box !important;
            border: none;
            box-shadow: -2px 2px 15px 0px #00000026;

            &.show {
                display: block !important;
            }

            ul {
                padding-left: 0 !important;
                position: relative;
                margin-top: 0 !important;

                .section-blocked {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    cursor: not-allowed;
                }

                li {
                    list-style: none !important;
                    color: #212529 !important;
                    text-align: left !important;
                    min-width: 150px !important;
                    // font-weight: 00;
                    padding: 5px 10px !important;
                    margin: 0.125rem 0 0 !important;
                    font-size: 13px !important;
                    cursor: pointer !important;

                    &:hover {
                        background-color: var(--btn-hover-bg) !important;
                        color: var(--btn-hover-color) !important;
                    }

                    &.active {
                        font-size: 14px !important;
                        font-weight: 700 !important;
                    }

                }
            }
        }

        .dropdown-divider {
            // height: 0 !important;
            // margin: 0 !important;
            // overflow: hidden !important;
            // border-top: 1px solid #e9ecef !important;
        }

        .dropdown-item {
            display: block !important;
            width: auto !important;
            padding: 0.25rem 1.5rem !important;
            clear: both !important;
            font-weight: 400 !important;
            color: #777777 !important;
            cursor: pointer !important;
            text-align: inherit !important;
            white-space: nowrap !important;
            background-color: transparent !important;
            border: 0 !important;
            // font-weight: 700 !important;
            padding: 10px !important;
            display: flex !important;
            align-items: center !important;

            svg {
                margin-right: 8px !important;
            }

            &:first-child {
                border-radius: 0;
            }

            &:last-child {
                border-radius: 0;
            }

            &:hover {
                background-color: var(--btn-hover-bg) !important;
                color: var(--btn-hover-color) !important;
            }

            &.no-hover:hover {
                color: #212529 !important;
                background-color: transparent !important;
            }
        }
    }

    .chat-subheader-wrapper {
        // background-color: var(--secondary-color);
        color: var(--header-color) !important;
        // padding: 5px;
        margin-top: 5px !important;
        // text-align: center;
        font-size: 11px !important;
        font-weight: 700 !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        margin-left: 10px !important;
        max-width: 270px !important;
        display: none !important;
    }
}

.talkk-web-chatbot-main-wrap {
    .show-div {
        display: block !important;
    }

    .hide-div {
        display: none !important;
    }

    .visibility-show {
        visibility: none !important;
    }

    .visibility-hide {
        visibility: hidden !important;
    }
}