.talkk-web-chatbot-main-wrap {
    .chat_talkk_widget {
        z-index: 999999;
        position: relative;
    }
    .loader-component{
        position: fixed !important;
        background-color: rgba(255,255,255,0.9) !important;
        top: 0 !important;
        width: 100% !important;
        height: 100vh !important;
        left: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        .loader-img{
            max-width: 120px !important;
        }
    }

    .mobi-pre-loader {
        position: fixed !important;
        width: 100% !important;
        height: 100% !important;
        background-color: #fff !important;
        z-index: 99999 !important;
        left: 0 !important;
        top: 0 !important;

        img {
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
        }
    }

    h3 {
        min-height: auto !important;
        letter-spacing: normal !important;
    }
}

.talkk-chat-wrapper-c {
    svg {
        transform: none !important;
        position: unset !important;
    }

    .h-auto {
        height: auto !important;
    }

    .PhoneInput {
        border: 1px solid #ced4da !important;
        border-radius: 4px !important;
        padding: 0.500rem 0.75rem !important;
        display: flex !important;
        align-items: center !important;

        input {
            border: none !important;

            &:focus {
                border: none !important;
                box-shadow: none !important;
                outline: none !important;
            }

            &:focus-visible {
                border: none !important;
                box-shadow: none !important;
                outline: none !important;
            }
        }

        img {
            width: 100% !important;
            height: 100% !important;
            border-radius: 0 !important;
        }
    }

    .css-26l3qy-menu {
        z-index: 99 !important;
        box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%) !important;

        .css-yt9ioa-option {
            color: #000 !important;
        }

        .css-9gakcf-option {
            background-color: var(--primary-color) !important;
        }

        .css-1n7v3ny-option {
            background-color: rgb(216, 215, 202) !important;
        }

        .css-4ljt47-MenuList {
            cursor: pointer !important;
            max-height: 400px !important;
        }
    }

    .has-html {
        p {
            margin: 0 !important;
            display: inline !important;
        }
    }

    .suggest-btn-wrapper {
        display: flex !important;
        flex-wrap: wrap !important;
        &.btn-bg-white {
            background-color: #fff !important;
        }

        button {
            margin-right: 10px !important;
            margin-top: 10px !important;
        }
    }
    .suggest-btn-wrapper2 {
        padding-left: 0 !important;
    }

    .chatwidget-wrapper {
        position: fixed !important;
        right: 45px !important;
        height: calc(100vh - 119px) !important;
        padding: 4px !important;

        @media (min-width:320px) and (max-width:768px) {
            height: 100% !important;
        }

        bottom: 95px !important;
        // width: calc(100vw - 79%) !important;
        aspect-ratio: 4/6.5;
        // background: url('../../../assets/images/esoft-bg.png') !important;
        // background: transparent !important;
        box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
        border-radius: var(--chat-border-radius) !important;
        overflow: hidden;
        z-index: 9999 !important;
        min-width: 360px !important;
        max-height: 700px !important;
        max-width: 500px !important;
        @media only screen and (max-width: 1400px) {
            max-width: 300px !important;
            min-width: 300px !important;
        }
        @media only screen and (max-width: 1300px) {
            min-width: 260px !important;
            max-width: 260px !important;
        }
        @media only screen and (max-width: 1200px) {
            min-width: 232px !important;
            max-width: 232px !important;
        }
        @media only screen and (max-width: 767px) {
            max-height: 100vh !important;
        }

        &.fadeIn {
            animation: toggle-height 0.4s ease-out !important;
            transition: 0.4s animation linear !important;
        }

        &.fadeOut {
            animation: toggle-height-out 0.4s ease-out !important;
            transition: 0.4s animation linear !important;
        }

        @media only screen and (max-width: 1023px) {
            width: calc(100vw - 60%) !important;
        }

        @media only screen and (max-width: 767px) {
            width: 263px !important;

            &.desktop-bot {
                width: 100% !important;
                height: 100% !important;
                right: 0 !important;
                top: 0 !important;
                border-radius: 0 !important;
                max-width: none !important;
                .drawer-overlay {
                    border-radius: 0 0 20px 20px !important;
                }
            }

            &.fadeIn {
                animation: none !important;
            }

            &.fadeOut {
                animation: none !important;
            }
        }

        &.mobilink-wrapper {
            width: 100% !important;
            right: 0 !important;
            height: 100% !important;
            top: 0 !important;
            border-radius: 0 !important;
            max-width: 390px !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
            transition: unset !important;
            animation: none !important;
            box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
            max-height: none !important;

            @media only screen and (max-width: 767px) {
                max-width: 100% !important;
            }
        }

        &.custom-full-width-bot {
            max-width: 100% !important;
            margin-right: auto;
            transform: none !important;
            right: auto !important;
            left: 0 !important;
        }

        &.has-pdf-sidebar-open {
            max-width: 66% !important;
            margin-right: auto;
            transform: none !important;
            // right: auto !important;
            // left: 0 !important;
            transition: 0.3s all linear !important;
        }

        @media only screen and (max-width: 767px) {
            &.has-pdf-sidebar-open {
                max-width: unset !important;
            }
        }
    }

    .fadeIn {
        animation: toggle-height 0.4s ease-out !important;
        transition: 0.4s animation linear !important;
    }

    .fadeOut {
        animation: toggle-height-out 0.4s ease-out !important;
        transition: 0.4s animation linear !important;
    }

    @keyframes toggle-height {
        0% {
            max-height: 0 !important;
            opacity: 0 !important;
        }

        100% {
            max-height: calc(100vh - 22%) !important;
            opacity: 1 !important;
        }
    }

    @keyframes toggle-height-out {
        0% {
            max-height: calc(100vh - 22%) !important;
            opacity: 1 !important;
        }

        100% {
            max-height: 0 !important;
            opacity: 0 !important;
        }
    }

    .chat-body-wrapper {
        height: calc(100% - 100px) !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        background-color: #fff !important;

        &.has-chat-screen {
            height: calc(100% - 238px) !important;
            padding-bottom: 10px;
            scroll-behavior: unset !important;
            padding-top: 0;
            display: block;
            &.has-quick-res-wrapper {
                height: calc(100% - 254px) !important;
            }
        }

        &.has-about-modal {
            overflow: hidden !important;
        }

        &.hide-typebar {
            height: calc(100% - 100px) !important;
        }

        &.has-no-footer {
            height: calc(100% - 100px) !important;
        }
    }

    // .has-title-with-button .bot-msg-btn-wrapper{
    //     margin-top: 0px !important;
    // }
    .bot-msg-wrapper {
        // display: flex !important;
        // justify-content: flex-start !important;
        width: 100% !important;
        // align-items: flex-end !important;
        margin: 7px 0 0px 0 !important;
        // margin-top: 25px !important;
        max-width: 100% !important;
        position: relative;
        &.has-title-with-button {
            margin-top: 7px !important;
        }

        .message-wrapper {
            margin-left: 0 !important;
            width: 100% !important;
        }

        .chat-image-wrapper {
            cursor: pointer !important;
        }

        .chat-image-wrapper img {
            width: 100% !important;
            height: 100% !important;
            margin-bottom: 0 !important;
            border-radius: 0 !important;
        }

        .message-timing,
        .message-agent {
            color: var(--bot-bubble-color) !important;
            font-weight: 500 !important;
            font-size: 10px !important;
            margin-bottom: 5px !important;
            display: inline-block !important;
            opacity: 0.6 !important;
            // left: 52px;
        }

        .message-timing,
        .message-agent {
            position: absolute !important;
            bottom: -22px !important;
            left: 0px !important;
        }

        .message-agent {
            position: absolute !important;
            top: 6px !important;
            bottom: auto !important;
            left: 11px !important;
            right: 0 !important;
            display: none !important;
        }

        .list-title {
            font-size: 17px !important;
            font-weight: bold !important;
        }

        .chat-list-wrapper {
            padding-left: 20px !important;
            margin: 0 !important;
            margin-bottom: 20px !important;

            li {
                margin-top: 2px !important;
                font-size: 13px !important;
            }
        }

        .subtitle {
            margin: 10px 0 !important;
        }

        .message-block {
            background-color: var(--bot-bubble-bg);
            text-align: left !important;
            color: var(--bot-bubble-color) !important;
            overflow-wrap: break-word !important;
            // box-shadow: 0px 2px 15px rgb(0 0 0 / 10%) !important;
            border-radius: 7px 7px 7px 0px !important;
            // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
            border: 1px solid var(--bot-bubble-bg) !important;
            padding: 13px !important;
            max-width: 100% !important;
            min-width: 100px !important;
            overflow-wrap: break-word !important;
            word-break: unset !important;
            width: fit-content !important;
            font-size: 17px !important;
            word-break: break-word !important;
            // margin-right: 30px;
            // word-break: break-all;
            position: relative !important;
            padding-bottom: 20px !important;
            padding-top: 11px !important;
            white-space: break-spaces;
            padding-bottom: 13px !important;
            margin-bottom: 5px !important;
            &.pdf-expired-type {
                margin-bottom: 36px !important;
            }

            &.has-pdf-docs {
                padding: 7px !important;
                padding-bottom: 0 !important;
                border-bottom: none !important;

                .document-wrapper img {
                    max-width: 27px !important;
                }

                .pdf-doc-wraper {
                    flex-direction: column;
                    position: relative;

                    .download-links-wrap {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }

                .message-timing,
                .message-agent {
                    display: none !important;
                }

                .pdf-info-wrap {
                    display: flex !important;
                    align-items: center !important;
                    width: 92% !important;
                }

                .pdf-name {
                    font-weight: 600 !important;
                    margin-bottom: 5px !important;
                    font-size: 12px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 64%;
                    white-space: nowrap;
                }

                .expired-pdf-link {
                    .react-pdf__Document {
                        opacity: 0.1 !important;
                        cursor: not-allowed !important;
                    }
                }

                .pdf-other-details {
                    font-size: 11px;
                }

                .type-devider {
                    display: inline-block !important;
                    width: 5px !important;
                    height: 5px !important;
                    background-color: #6D7578 !important;
                    border-radius: 50% !important;
                    margin: 0 6px !important;
                }

                .react-pdf__Page__textContent {
                    position: unset !important;
                    width: 100% !important;
                    height: auto !important;
                    display: none;
                }

                .download-expiry-wrapper {
                    display: flex !important;
                    align-items: flex-start !important;
                    justify-content: space-between !important;
                    color: #EC7208 !important;
                    width: 100% !important;
                    position: absolute !important;
                    bottom: -37px !important;
                    font-size: 11px !important;
                    left: 0 !important;
                    font-weight: 500 !important;

                    &.text-danger {
                        color: #D50001 !important;
                        bottom: -25px !important;
                    }

                    .download-timer {
                        font-weight: 600 !important;
                    }

                    .download-text {
                        text-transform: uppercase !important;
                        max-width: 82%;
                    }
                }

                .file-type {
                    text-transform: uppercase !important;
                }

                .download-img {
                    max-width: 35px !important;
                }

                .react-pdf__Page__canvas {
                    border-radius: 8px 8px 0 0 !important;
                    width: 100% !important;
                    height: auto !important;
                }

                .pdf-bottom-info-wrapper {
                    background-color: #E0E6EF !important;
                    width: 100% !important;
                    @media only screen and (max-width: 767px) {
                        width: calc(100% + 0px) !important;
                        margin-left: 0 !important;
                    }
                    margin-left: 0 !important;
                    padding: 20px 15px !important;
                    // margin-top: 5px !important;
                    display: flex !important;
                    align-items: center !important;
                }
            }
        }

        &.agent-msg-wrapper .message-block {
            background-color: var(--agent-bubble-bg) !important;
            border-color: var(--agent-bubble-bg) !important;
            color: var(--agent-bubble-color) !important;

            .message-timing,
            .message-agent {
                color: var(--agent-bubble-color) !important;
            }
        }

        .message-title {
            font-weight: 400 !important;
        }

        img {
            width: 20px !important;
            height: 20px !important;
            border-radius: 50% !important;
        }
        .bot-extracted-question img{
            width: 25px !important;
            height: 25px !important;
        }

        .attachment-wrapper img {
            width: 100% !important;
            height: 100% !important;
            max-height: 320px !important;
            border-radius: 0 !important;
            margin-bottom: 0 !important;
        }

        audio {
            max-width: 100% !important;
            height: 40px !important;
        }

        .pdf-wrapper {
            .document-wrapper {
                &.pdf-doc-wraper {
                    flex-direction: column !important;
                    align-items: flex-start !important;
                    height: 100px !important;
                    overflow: hidden;

                    @media (min-width:320px) and (max-width:768px) {
                        width: 350px !important;
                    }

                    canvas {
                        width: 100% !important;
                        // max-height: 100px !important;
                        height: auto !important;
                    }
                }

                .react-pdf__Page {
                    max-height: 150px !important;
                    overflow: hidden !important;
                }
            }

            pdf-doc-detail {
                height: 50px !important;
            }
        }

        .document-wrapper {
            display: flex !important;
            align-items: center !important;
            color: var(--bot-bubble-color) !important;

            .react-pdf__Page {
                max-height: 88px !important;
                overflow: hidden !important;
            }

            .detail-item {
                // display: flex;
                // align-items: flex-start;
                font-size: 13px !important;
                margin-bottom: 3px !important;
                color: var(--bot-bubble-color) !important;

                span:first-child {
                    word-break: keep-all !important;
                    margin-right: 5px !important;
                }

                b {
                    width: 90px !important;
                }
            }

            img {
                max-width: 50px !important;
                margin-right: 10px !important;
            }
        }

        .map-wrapper {
            a {
                display: block !important;
            }

            img {
                width: 100% !important;
                height: auto !important;
                border-radius: 4px !important;
                margin-bottom: 0 !important;
            }
        }
        .swiper-slide img{
            height: 140px !important;
            object-fit: contain;
            @media only screen and (min-width: 1500px) {
                height: 180px !important;
            }
            @media only screen and (min-width: 1700px) {
                height: 200px !important;
            }
            @media only screen and (max-width: 767px) {
                height: 300px !important;
            }
        }
        .tlk-map-wrapper img{
            height: auto !important;
            width: 100% !important;
        }

        .contact-card-wrapper {
            color: var(--bot-bubble-color) !important;

            a {
                img {
                    width: 30px !important;
                    height: 30px !important;
                    margin-right: 10px !important;
                }

                color: var(--bot-bubble-color) !important;
                margin-top: 7px !important;
                display: flex !important;
                align-items: center !important;

                &:hover {
                    opacity: 0.7 !important;
                }
            }
        }

        .suggect-option {
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            // margin-top: 5px !important;
            cursor: pointer !important;
            color: var(--bot-bubble-color) !important;
            transition: 0.2s all linear !important;
            width: 100% !important;
            padding: 10px !important;
            border-top: 1px solid #D6D6D6 !important;

            &:first-child {
                border-top: none !important;
            }

            &:hover {
                opacity: 0.3 !important;
            }
        }

        button {
            cursor: pointer !important;
            font-family: "Montserrat", sans-serif !important;
        }

        .suggest-devider {
            width: 100% !important;
            height: 1px !important;
            background-color: #d5d5d5 !important;
            margin-top: 10px !important;
        }

        .leave-message {
            display: block !important;
            width: 100% !important;
            margin: auto !important;
            color: var(-- bot-bubble-color) !important;
            transition: 0.2s all linear !important;
            cursor: pointer !important;
            text-align: center !important;
            margin: 8px 0 4px !important;

            &:hover {
                opacity: 0.3 !important;
            }

            i {
                margin-right: 5px !important;
            }
        }

        .bot-img {
            display: block !important;
            position: absolute;
            top: 10px;
            left: -15px;
            background: rgba(255, 255, 255, 0.1);
            padding: 0 !important;
            height: 26px !important;
            width: 26px !important;
        }

        .chat-iframe-page {
            position: absolute;
            width: 0;
            height: 0;
            top: 78px;
            z-index: 99;
            left: -2000px;
            transition: 0.3s height ease-in-out;

            &.show-iframe {
                left: 0;
                width: 100%;
                height: calc(100% - 101px);
            }
        }

        .bot-msg-btn-wrapper {
            text-align: left !important;
            overflow-wrap: break-word !important;
            // padding: 10px;
            width: 100% !important;
            overflow-wrap: break-word !important;
            word-break: unset !important;
            // margin-top: 10px !important;
            // margin-left: 10px !important;
            padding: 0 !important;

            .btn-inlines-outline {
                width: 47% !important;
                margin: 0 !important;
                text-align: center !important;
                border-radius: 4px !important;
                padding: 15px 12px !important;
                margin-bottom: 10px !important;
                width: 100% !important;
                min-width: auto !important;
                max-width: 400px !important;
                padding: 10px 10px !important;
                border-radius: 4px !important;
                font-weight: 500 !important;
                margin-right: 10px !important;
                font-size: 16px !important;

                &:nth-child(1) {
                    margin-left: 0 !important;
                }

                // &:last-child{
                //     margin-bottom: 0 !important;
                // }
                // &:nth-last-child(2){
                //     margin-bottom: 0 !important;
                // }
                &.btn-disabled {
                    background-color: #BBBBBB !important;
                    border-color: #BBBBBB !important;
                    color: rgba(85, 85, 85, 0.5) !important;
                    cursor: not-allowed !important;
                }
            }

            &.has-odd-button {
                // .btn-inlines-outline{
                //     // nes css added by shubham
                //     &:last-child{
                //         width: 98% !important;
                //         margin-bottom: 0 !important;
                //     }
                //     &:nth-last-child(2){
                //         margin-bottom: 10px !important;
                //     }
                //     // nes css added by shubham
                // }
            }

            &.inline-btn-wrapper {
                padding: 5px 0 !important;

                // nes css added by shubham
                padding: 10px !important;
                // background-color: var(--bot-bubble-bg);
                border-radius: 5px !important;
                // width: fit-content !important;
                margin-top: -1px !important;
                margin-bottom: 10px !important;
                background-color: transparent !important;
                padding: 0 !important;
                // nes css added by shubham
            }

            &.leave-btn-wrapper {
                background-color: var(--bot-bubble-bg) !important;
                box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px !important;
                border: 1px solid var(--bot-bubble-bg) !important;
                border-radius: 7px 7px 7px 7px !important;
                color: var(--bot-bubble-color) !important;
            }

            .li-item-wrapper {
                width: 100% !important;
                border-top: 1px solid #ECECEC !important;
                padding: 5px !important;

                &:first-child {
                    border-top: none !important;
                }
            }

            .helper-text {
                display: block !important;
                text-align: center !important;
                width: 100% !important;
                font-size: 10px !important;
                color: var(--bot-bubble-color) !important;
                opacity: 0.7 !important;
                margin-bottom: 10px !important;
            }
        }

        .suggest-btn-wrapper.bot-msg-btn-wrapper.leave-btn-wrapper {
            display: block !important;
            width: 100% !important;
            min-width: 72% !important;
        }
    }
    .tlk-full-map-wrapper{
        max-width: 80%;
        margin-top: auto;
        margin-bottom: auto;
        .swiper-slide{
            // background-color: #fff;
            padding: 10px;
        }
        @media only screen and (max-width: 1023px) {
            max-width: 95%;
            .swiper-slide{
                padding: 0;
            }
        }
    }
    .swiper{
        .swiper-slide{
            // border: 1px solid var(--btn-bg);
            border-radius: 4px;
            margin-right: 15px;
            @media only screen and (max-width: 1023px) {
                margin-right: 0;
            }
            cursor: pointer;
        }
        .swiper-button-prev,
        .swiper-button-next {
            color: #fff;
            border-radius: 5px;
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 19px;
            background: linear-gradient(180deg, rgba(0,0, 0, 0.9) 0%, rgba(153, 153, 153, 0) 123.41%);
            border-radius: 50%;
            svg{
                font-size: inherit;
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
        .swiper-button-disabled{
            display: none;
        }
    }
    .full-image-wrapper{
        .swiper-button-prev,
        .swiper-button-next {
            color: #000;
            background: #fff;
        }
    }
    .tlk-full-map-wrapper-mobi, .swiper-mobile{
        .swiper-slide{
            // margin-right: 0 !important;
        }
    }
    .swiper-mobile{
        margin-bottom: 10px;
    }
    .tlk-full-map-wrapper-mobi{
        max-width: 100%;
    }
    .user-msg-wrapper {
        display: flex !important;
        justify-content: flex-end !important;
        width: 100% !important;
        align-items: flex-end !important;
        margin: 20px 10px 30px 0 !important;
        font-size: 17px !important;
        font-family: "Montserrat", sans-serif !important;
        width: 100% !important;
        text-align: right !important;
        margin-top: 20px !important;
        position: relative !important;

        .attachment-image {
            width: 100%;
            cursor: pointer;
            max-height: 216px;
        }

        .message-wrapper {
            width: calc(100% - 34px) !important;
            margin-right: 15px !important;
        }

        .message-timing,
        .message-agent {
            color: #000 !important;
            font-weight: 500 !important;
            font-size: 10px !important;
            margin-bottom: 5px !important;
            display: inline-block !important;
            opacity: 0.5 !important;
            position: absolute !important;
            right: 0 !important;
            bottom: -22px !important;
        }

        .message-agent {
            position: absolute !important;
            top: 5px !important;
            bottom: auto !important;
            left: 11px !important;
            right: auto !important;
            display: none !important;
        }

        .message-block {
            background-color: var(--user-bubble-bg) !important;
            text-align: left !important;
            color: var(--user-bubble-color) !important;
            overflow-wrap: break-word !important;
            // box-shadow: 0px 2px 15px rgb(0 0 0 / 10%) !important;
            border-radius: 7px 7px 0px 7px !important;
            border: 1px solid var(--user-bubble-bg) !important;
            padding: 13px !important;
            // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
            max-width: 92% !important;
            overflow-wrap: break-word !important;
            margin-left: auto !important;
            width: fit-content !important;
            min-width: 100px !important;
            position: relative !important;
            padding-bottom: 12px !important;
            padding-top: 12px !important;
            word-break: break-word !important;

            //new bot css
            // background-color: transparent !important;
            // width: 100% !important;
            // margin-left: 0 !important;
            // border: none !important;
            // text-align: left !important;
            // padding: 0 !important;
        }

        .user-avatar {
            width: 35px !important;
            height: 35px !important;
            line-height: 35px !important;
            text-align: center !important;
            background-color: var(--secondary-color) !important;
            color: #fff !important;
            border-radius: 50% !important;
            margin: 0 7px !important;
            margin-bottom: 16px !important;
        }

        .user-img {
            margin: 0 10px !important;
            margin-bottom: 16px !important;
            width: 35px !important;
            height: 35px !important;
            border-radius: 50% !important;
        }
    }
    &.theme-vintage{
        .user-msg-wrapper{
            padding-left: 16px;
            margin:  20px 0 0 0 !important;
            .message-block {
                background-color: transparent !important;
                width: 100% !important;
                margin-left: 0 !important;
                border: none !important;
                text-align: left !important;
                padding: 0 !important;
                color: #7ECDFE !important;
                padding-bottom: 12px !important;
                padding-top: 12px !important;
            }
            .message-timing{
                position: unset !important;
                margin-top: 7px !important;
                right: 14px !important;
                bottom: 1px !important;
            }
        }
        .bot-msg-wrapper{
            .message-block {
                background-color: transparent !important;
                color: #fff !important;
                border: none !important;
                padding-left: 20px !important;
            }
        }
        .chat-body-wrapper.has-chat-screen {
            
        }
    }

    .message-timing {
        color: #B1B1B1 !important;
        font-weight: 500 !important;
        font-size: 11px !important;
    }

    .about-modal-wrapper {
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        background-color: rgba(0, 0, 0, 0.2) !important;
        width: 100% !important;
        height: calc(100% - 76px) !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        z-index: 999 !important;

        &.in-chat {
            height: calc(100% - 78px) !important;
        }

        .modal-wrapper {
            background-color: #fff !important;
            border-radius: 20px !important;
            max-width: calc(100% - 50px) !important;
            // height: calc(100% - 40px) !important;
            max-height: 250px !important;
            height: 250px !important;
        }

        .m-header {
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            padding: 15px !important;
            // margin-bottom: 10px !important;
            border-bottom: 1px solid #e1e1e1 !important;

            svg {
                cursor: pointer !important;
            }

            h4 {
                margin: 0 !important;
            }
        }

        h4 {
            font-size: 16px !important;
        }

        .m-body {
            padding: 20px !important;
            max-height: calc(100% - 105px) !important;
            overflow-y: auto !important;

            h4 {
                margin: 0 !important;
            }

            p {
                margin-bottom: 0 !important;
                margin: 0 !important;
                font-size: 14px !important;
            }
        }

        .m-footer {
            padding: 10px;
            text-align: right;

            .btn-orange {
                background-color: var(--btn-bg);
                color: var(--btn-color);
                padding: 6px 10px;
                border-radius: 4px;
                display: inline-block;
                min-width: 69px;
                text-align: center;
                font-size: 14px;
            }
        }
    }

    .error-modal {
        .modal-wrapper {
            height: 160px !important;
            color: #db1111;
        }

        .m-body {
            max-height: calc(100% - 70px) !important;
        }
    }

    .leave-message-wrapper {
        // position: absolute;
        // bottom: 0;
        // right: 0;
        // width: auto;
        // left: 0;
        // z-index: -1;
        // background-color: #fff;
        // padding: 20px;
        // border-radius: 12px 12px 20px 20px;
        // height: 0;
        // opacity: 0;
        // transition: all 0.4s linear;

        //new css
        border-radius: 12px 12px 20px 20px;
        position: absolute !important;
        height: 0 !important;
        padding: 0 !important;
        left: 0 !important;
        width: 100% !important;
        bottom: 0 !important;
        background: #fff !important;
        color: white !important;
        max-height: calc(100% - 140px) !important;
        transform: translateY(100%) !important;
        overflow-y: auto !important;
        z-index: 9999 !important;
        transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) !important;

        &.br-0 {
            border-radius: 0 !important;
        }

        &.show-leave-form {
            // max-height: calc(100% - 89px);
            // opacity: 1;
            // height: fit-content;
            // z-index: 99;
            // overflow-y: auto;

            //new css
            height: initial !important;
            transform: translateY(0) !important;
            padding: 20px !important;
        }

        .green-mark {
            background: var(--header-bg-color) !important;
            content: "" !important;
            height: 3px !important;
            margin: 0px auto !important;
            width: 25% !important;
        }

        .message-form-group {
            margin-bottom: 20px !important;

            .form-control {
                width: 100% !important;
                box-sizing: border-box !important;
            }

            textarea {
                resize: none !important;
            }
        }

        .quick-btn-list-wrapper {
            margin: 0 !important;
            padding-left: 0 !important;

            li {
                padding: 12px 5px !important;
                border-bottom: 1px solid #ccc !important;
                cursor: pointer !important;
                display: flex !important;
                align-items: center !important;

                &:last-child {
                    border: none !important;
                    padding-bottom: 0 !important;
                }

                svg {
                    color: #ccc !important;
                    margin-right: 8px !important;
                }
            }
        }

        .drawer-header {
            text-align: center !important;
            margin: 20px auto !important;
            color: #000000 !important;
            border-bottom: 2px solid #93939380;
            padding-bottom: 24px;
        }

        .drawer-body {
            height: calc(100% - 55px) !important;
            overflow-y: auto !important;
            overflow-x: hidden !important;
            color: #000 !important;
            .form-control:focus{
                color: #000 !important;
            }
        }

        .comment-thanku-wrapper {
            text-align: center !important;
            display: flex !important;
            flex-direction: column !important;
            justify-content: center !important;
            height: 84% !important;

            img {
                max-width: 50% !important;
            }

            i {
                font-size: 40px !important;
                color: var(--secondary-color) !important;
            }

            .leave-btn-wrapper {
                display: flex !important;
                // align-items: center !important;
                position: absolute !important;
                width: calc(100% - 40px) !important;
                bottom: 20px !important;
                left: 20px !important;

                button {
                    width: 100% !important;
                    padding: 10px !important;

                    &:first-child {
                        margin-right: 10px !important;
                    }
                }
            }

            .thankyou-btn-wrapper {
                width: 100% !important;
                position: unset !important;

                button {
                    text-align: center !important;
                }

                .m-0 {
                    margin-right: 0 !important;
                }
            }
        }

        .react-tel-input {
            border: 1px solid #ced4da !important;
            height: 41px !important;
            // padding: 0.375rem 0.75rem !important;
            border-radius: 0.25rem !important;
        }
        .react-tel-input{
            padding: 7px 12px !important;
            .country-list .search-box{
                padding-left: 0 !important;
            }
            .form-control {
                min-height: 27px !important;
                border-radius: 4px !important;
                padding: 0 !important;
                padding-left: 45px !important;
            }
        }
    }

    .custom-full-width-bot {
        .leave-message-wrapper {
            right: 0 !important;
            left: auto !important;
            max-width: 600px !important;
            max-height: none !important;
            border-radius: 0 !important;
            transition: none !important;
            display: none;
            &.show-leave-form {
                height: 100vh !important;
            }

            .green-mark {
                display: none;
            }

            .drawer-header {
                margin-top: 0 !important;
            }
            .form-control{
                color: #fff !important;
                &:focus{
                    color: #fff !important;
                }
            }
        }

        .bot-menu-wrapper{
            max-width: 620px !important;
            box-shadow: 12px 13px 31px 5px rgba(255, 255, 255, 0.1) inset;
            background: rgba(0,0,0,0.5) !important;
            border: 1px solid rgba(255, 255, 255, 0.12);
            display: none;
            padding: 30px 62px !important;
            @media only screen and (max-width: 1023px) {
                padding: 15px 20px !important;
            }
            ::-webkit-scrollbar{
                width: 5px !important;
            }
            .drawer-header {
                color: #fff !important;
                text-align: left !important;
                padding: 0 !important;
                border: none;
                margin-top: 29px !important;
                padding-bottom: 20px !important;
                font-weight: 600;
                margin-bottom: 20px !important;
                position: relative;
                &:after{
                    content: '';
                    width: 100%;
                    height: 1.4px;
                    background: red;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background: linear-gradient(90deg, #7ECDFE 2.3%, #388FC4 40.17%, #FFFFFF 53.1%, #388FC4 62.87%, #7ECDFE 100%);
                }
            }
            .closebar-toggler{
                top: 24px;
                color: #fff;
                font-weight: bold;
                right: 20px;
            }
            button{
                width: 100%;
                text-align: left;
                background-color: transparent;
                outline: none;
                border: none;
                font-size: 16px;
                margin-bottom: 25px;
                color: #fff;
                padding: 0 !important;
                // padding-right: 25px !important;
                line-height: 24px;
                transition: color 0.3s linear;
                font-family: "Montserrat", sans-serif !important;
                &:hover{
                    color: var(--btn-bg);
                }
            }
        }
        .drawer-body{
            max-height: 300px !important;
            overflow-y: auto !important;
        }
        .suggest-btn-wrapper.bot-msg-btn-wrapper.leave-btn-wrapper {
            min-width: 200px !important;
            max-width: 300px !important;
        }
    }

    .drawer-overlay {
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: 100% !important;
        height: 100% !important;
        background-color: rgba(0, 0, 0, 0.6) !important;
        border-radius: 20px !important;
        z-index: 9999 !important;
        cursor: pointer !important;
        &.menu-overlay{
            background-color: rgba(0, 0, 0, 0.9) !important;
        }
    }

    .feedback-wraper {
        text-align: center !important;
        padding: 20px !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
        margin: 20px !important;
        border-radius: 6px !important;
        background-color: #fff !important;

        padding: 0 !important;
        box-shadow: none !important;
        height: 100%;
        margin: 0 !important;

        form {
            height: 100%;
        }

        .form-inner-wrapper {
            max-height: calc(100% - 53px);
            min-height: calc(100% - 57px);
            overflow-y: auto;
            padding: 20px;
            padding-top: 35px;

            .welocome-msg {
                max-width: 100% !important;
                margin-bottom: 15px !important;
                font-size: 15px !important;
            }
        }

        .form-inner-layout {
            padding: 20px;
            background-color: #F5F5F5;
            border-radius: 6px;
        }

        .lets-start-wrapper {
            position: relative;
            padding: 0 15px;
            padding-top: 15px;

            &::after {
                content: "";
                background: var(--header-bg-color) !important;
                width: calc(100% + 16px);
                height: 2px;
                position: absolute;
                top: 0;
                left: -8px;
                display: none !important;
            }

            .btn {
                border-radius: 4px !important;
                padding: 10px !important;
            }
        }

        img {
            width: 100% !important;
            margin-bottom: 15px !important;
        }

        .prechat-image {
            max-height: 272px;
            object-fit: contain;
        }

        .react-simple-star-rating {
            margin-top: 3px !important;
        }

        .filled-icons {
            color: var(--primary-color) !important;
        }

        h2 {
            margin-bottom: 0 !important;
            font-size: 25px !important;
        }

        p {
            margin-top: 10px !important;
            font-size: 15px !important;
        }

        .text-align-left {
            text-align: left !important;
        }

        .rating-card {
            text-align: center !important;

            label {
                font-weight: 600 !important;
                font-size: 15px !important;
            }
        }

        .form-input-group {
            text-align: left !important;
            margin-top: 10px !important;
            margin-bottom: 15px !important;

            textarea {
                resize: none !important;
            }

            .form-control {
                box-sizing: border-box !important;
            }
        }

        textarea {
            resize: none !important;
        }
    }

    .thank-you-msg-wrapper {
        height: calc(100% - 100px) !important;
        position: relative !important;
        background-color: #f9f9f9 !important;
        box-shadow: none;
        margin: 20px !important;
        border-radius: 6px !important;
        background-color: #F5F5F5 !important;
        margin-top: 40px !important;
        padding: 20px !important;

        .thank-you-content {
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            width: 90% !important;
            text-align: center !important;
            transform: translate(-50%, -50%) !important;

            img {
                // height: 180px !important;
                width: 100%;
                object-fit: contain;
                padding: 10px;
                max-width: 220px;
            }

            p {
                font-size: 17px !important;
                font-weight: 500 !important;
                text-align: center !important;
                margin-top: 10px !important;
            }
        }
    }

    .connecting-agent {
        margin-top: 10px !important;
        text-align: center !important;

        &.hide-call {
            display: none !important;
        }

        &.connecting-to-bot-disappear {
            animation: cssAnimation2 0s 3s forwards !important;
            visibility: visible !important;
        }

        i {
            font-size: 30px !important;
            color: #A4A4A3 !important;
        }

        p {
            font-size: 13px !important;
            color: #A4A4A3 !important;
        }
    }

    .connected-to {
        font-size: 14px !important;
        color: #A4A4A3 !important;
        position: relative !important;
        padding: 0 15px !important;

        span {
            background: #f2f2f2 !important;
            display: inline-block !important;
            max-width: 75% !important;
            word-break: break-all !important;
            padding: 0 5px !important;
        }

        &::before {
            content: '' !important;
            width: calc(100% - 30px) !important;
            height: 1px !important;
            border-bottom: 1px dashed #a4a4a3 !important;
            position: absolute !important;
            left: 15px !important;
            top: 50% !important;
            transform: translateY(-50%) !important;
            z-index: -1 !important;
        }
    }

    .quick-responce-wrapper {
        position: absolute !important;
        bottom: 104px !important;
        width: 100% !important;
        /* display: flex !important; */
        white-space: nowrap !important;
        overflow: auto !important;
        padding: 10px 0 !important;
        background-color: #fff !important;

        button {
            display: inline-block !important;
            margin: 0 4px !important;
        }
    }

    .agent-list-wrapper {
        .agent-item {
            display: flex !important;
            align-items: center !important;
            margin-bottom: 9px !important;
            color: #2672CC !important;
            font-size: 14px !important;
            cursor: pointer !important;
            transition: 0.2s all linear !important;

            &:hover {
                opacity: 0.4 !important;
            }

            i {
                margin-right: 10px !important;
            }
        }

        .voice-item {
            margin-bottom: 0 !important;
        }
    }

    .voice-list-wrapper {

        // margin-top: 20px !important;
        .voice-item {
            display: flex !important;
            justify-content: space-between !important;
            align-items: center !important;
            cursor: pointer !important;

            &:hover {
                opacity: 1 !important;
            }

            &.active {
                color: var(--btn-bg) !important;
                font-weight: 600 !important;
            }

            .btn-default {
                background-color: transparent !important;
                color: var(--btn-bg) !important;
                border: 1px solid var(--btn-bg) !important;
                border-radius: 4px !important;
                padding: 2px 9px !important;

                &:hover {
                    background-color: var(--btn-bg) !important;
                    color: var(--btn-color) !important;
                }
            }
        }

        .action-wrapper {
            display: none !important;
            align-items: center !important;

            svg {
                margin-right: 5px !important;
            }

            svg,
            button {
                cursor: pointer !important;
            }
        }
    }

    .btn-seprate {
        margin: 12px !important;
        padding: 10px 15px !important;
        border-radius: 4px !important;
        font-size: 14px !important;
        margin-left: 0 !important;

        &.btn-disabled {
            background-color: #BBBBBB !important;
            border-color: #BBBBBB !important;
            color: rgba(85, 85, 85, 0.5) !important;
            cursor: not-allowed !important;
        }

    }

    .has-bot-image .btn-seprate {
        // margin-left: 58px;
    }

    .calender-card {
        margin: 10px 0 15px 10px !important;
        background-color: #fff !important;
        border: 1px solid rgba(187, 187, 187, 0.3) !important;
        border-radius: 5px !important;
        max-width: 92% !important;
        width: fit-content !important;
        overflow-x: auto !important;
        position: relative;

        .calender-locked {
            position: absolute;
            height: 100%;
            width: 100%;
            cursor: not-allowed;
            z-index: 2;
        }

        .calender-card-head {
            color: rgba(85, 85, 85, 0.5) !important;
            border-bottom: 1px solid rgba(187, 187, 187, 0.2) !important;
            padding: 14px 18px !important;
        }

        .react-datepicker {
            margin: 10px !important;
            border: none !important;
        }

        .react-datepicker__header {
            background-color: #fff !important;
            border-bottom: 1px solid rgba(187, 187, 187, 0.2) !important;
        }

        .react-datepicker__month-read-view--down-arrow,
        .react-datepicker__month-year-read-view--down-arrow,
        .react-datepicker__navigation-icon:before,
        .react-datepicker__year-read-view--down-arrow {
            border-width: 2px 2px 0 0 !important;
            height: 6px !important;
            top: 10px !important;
            width: 6px !important;
        }

        .react-datepicker-time__header,
        .react-datepicker-year-header,
        .react-datepicker__current-month {
            color: #555555 !important;
        }

        .react-datepicker__day,
        .react-datepicker__day-name,
        .react-datepicker__time-name {
            color: #555555 !important;
        }

        .react-datepicker__day--in-range,
        .react-datepicker__day--in-selecting-range,
        .react-datepicker__day--selected,
        .react-datepicker__month-text--in-range,
        .react-datepicker__month-text--in-selecting-range,
        .react-datepicker__month-text--selected,
        .react-datepicker__quarter-text--in-range,
        .react-datepicker__quarter-text--in-selecting-range,
        .react-datepicker__quarter-text--selected,
        .react-datepicker__year-text--in-range,
        .react-datepicker__year-text--in-selecting-range,
        .react-datepicker__year-text--selected {
            background-color: var(--btn-bg) !important;
            color: var(--btn-color) !important;
        }

        .react-datepicker__day--keyboard-selected {
            background-color: #fff;
        }

        .react-datepicker__day--disabled,
        .react-datepicker__month-text--disabled,
        .react-datepicker__quarter-text--disabled,
        .react-datepicker__year-text--disabled {
            color: #ccc !important;
            cursor: not-allowed !important;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            padding: 5px !important;
        }

        .react-datepicker__current-month {
            margin-bottom: 9px !important;
        }

        .appointment-card {
            padding: 20px !important;
            border-top: 1px solid rgba(187, 187, 187, 0.2) !important;

            .appointment-head {
                color: rgba(85, 85, 85, 0.5) !important;
            }

            .appointmnet-btn-wrapper {
                display: flex !important;
                flex-wrap: wrap !important;
                margin-top: 20px !important;

                .appoint-not-available {
                    width: 100%;
                    text-align: center;
                }
            }

            .btn-outline {
                border-radius: 4px !important;
                padding: 12px !important;
                min-width: 77px !important;
                text-align: center !important;
                margin-right: 10px !important;

                &.active-btn {
                    background-color: var(--btn-bg) !important;
                    color: var(--btn-color) !important;
                }
            }
        }
    }

    .appointment-footer-wrapper {
        position: absolute !important;
        bottom: 0 !important;
        z-index: 98 !important;
        padding: 20px !important;
        background-color: #f9f9f9 !important;
        width: 100% !important;
        border-top: 1px solid #ccc !important;
        left: 0 !important;
        min-height: 100px !important;
        display: flex;
        align-items: center;

        button {
            &:last-child {
                margin-left: 10px !important;
            }
        }

        svg {
            position: absolute !important;
            right: 20px !important;
            font-size: 38px !important;
            background-color: #f9f9f9 !important;
            border: 1px solid #ccc !important;
            top: -18px !important;
            color: #555 !important;
            padding: 4px !important;
            border-radius: 50% !important;
            cursor: pointer !important;
        }

        button,
        .footer-upload-label {
            width: 100% !important;
            margin: 0 !important;
            padding: 14px !important;
            margin-top: 8px !important;
            text-align: center !important;
        }
    }

    .mobile-uploads-grouping {
        display: flex;
        align-items: center;
        justify-content: center;

        label {
            text-align: center;
            font-size: 14px;
            cursor: pointer;
        }

        .icon-holder {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: var(--btn-bg);
            color: var(--btn-color);
            border-radius: 50%;
            margin: 0 20px;
            margin-bottom: 7px;

            svg {
                font-size: 20px;
            }
        }
    }

    .list-drawer-wrapper {
        .green-mark {
            background: #BBBBBB !important;
            height: 6px !important;
            width: 19% !important;
            border-radius: 4px !important;
        }

        .drawer-header {
            margin-bottom: 0 !important;
            position: relative !important;
            padding-bottom: 0 !important;

            input {
                border: none !important;
                padding: 10px 0 !important;
                font-size: 17px !important;

                &:focus {
                    box-shadow: none !important;
                    border: none !important;
                }
            }

            svg {
                position: absolute !important;
                right: 8px !important;
                color: #ccc !important;
                top: 15px !important;
                font-size: 24px !important;
            }
        }

        .agent-item {
            color: #555555 !important;
            font-size: 16px !important;
            padding: 16px 0 !important;
            // border-top: 1px solid rgba(187, 187, 187, 0.3) !important;
        }
    }

    .full-image-wrapper {
        position: absolute !important;
        top: 50% !important;
        transform: translateY(-50%) !important;
        right: 0 !important;
        width: 0 !important;
        box-sizing: border-box !important;
        height: 0 !important;
        padding: 20px !important;
        background-color: rgba(0, 0, 0, 0.8) !important;
        opacity: 0 !important;
        z-index: -999 !important;
        display: flex !important;
        justify-content: center !important;
        transition: none !important;

        &.open-full-image {
            opacity: 1 !important;
            height: 100% !important;
            width: 100% !important;
            z-index: 999999 !important;
            padding: 20px !important;
        }

        img {
            height: auto !important;
            width: 100% !important;
            object-fit: contain !important;
        }

        i {
            font-size: 40px !important;
            color: #fff !important;
            position: absolute !important;
            right: 20px !important;
            top: 20px !important;
            cursor: pointer !important;
            transition: 0.2s all linear !important;

            &:hover {
                opacity: 0.6 !important;
            }
        }
    }

    .mobilink-wrapper .full-image-wrapper {
        position: absolute !important;

        &.open-full-image {
            height: 100vh !important;
            position: fixed !important;
            img {
                width: 100% !important;
                @media only screen and (max-width: 1023px) {
                    height: auto !important;
                }
            }
        }
    }

    .item-checkbox {
        margin-left: 0 !important;
        display: flex !important;
        margin-bottom: 9px !important;
        align-items: center !important;
        cursor: pointer !important;

        &.fw-n {
            font-weight: normal !important;
        }

        input {
            display: none !important;
        }

        .check {
            width: 20px !important;
            height: 20px !important;
            border: 2px solid #a1a1a1 !important;
            border-radius: 5px !important;
            padding: 0 !important;
            position: relative !important;
            margin-right: 10px !important;

            &::after {
                content: "" !important;
                position: absolute !important;
                display: none !important;
                left: 5px !important;
                top: 1px !important;
                width: 4px !important;
                height: 8px !important;
                border: solid white !important;
                border-width: 0 3px 3px 0 !important;
                transform: rotate(45deg) !important;
            }
        }

        input:checked~.check {
            background-color: var(--primary-color) !important;
            border-color: var(--primary-color) !important;

            &::after {
                display: block !important;
            }
        }
    }

    .item-radio {
        margin-bottom: 15px !important;
        position: relative !important;
        display: block !important;
        cursor: pointer !important;
        width: 100% !important;
        margin-bottom: 12px !important;

        &.mb-0 {
            margin-bottom: 0 !important;
        }

        &.radio-inline {
            padding-left: 0 !important;
            display: inline-block !important;

            +.radio-inline {
                margin-left: 10px !important;
            }
        }

        &.radio-block {
            display: block !important;
        }

        input[type="radio"] {
            opacity: 0 !important;
            position: absolute !important;
            z-index: -1 !important;

            &:checked~.radio-content {
                &::after {
                    content: "" !important;
                    display: block !important;
                    position: absolute !important;
                    top: 50% !important;
                    left: 50% !important;
                    width: 10px !important;
                    height: 10px !important;
                    background-color: var(--primary-color) !important;
                    border-radius: 10px !important;
                    margin-left: -5px !important;
                    margin-top: -5px !important;
                }

                .fa-circle-o {
                    &:before {
                        content: "\f111" !important;
                        color: var(--primary-color) !important;
                    }
                }
            }

        }

        .radio-content {
            position: absolute !important;
            left: 0 !important;
            top: 50% !important;
            margin-top: -10px !important;
            cursor: pointer !important;
            width: 20px !important;
            height: 20px !important;
            border: 2px solid #ccc !important;
            border-radius: 20px !important;
            background-color: #fff !important;
            display: block !important;

            &:hover {

                .text,
                .fa {
                    color: var(--primary-color) !important;
                }
            }

            .fa {
                position: absolute !important;
                left: 0 !important;
                top: 50% !important;
                color: #ddd !important;
                -webkit-transform: translateY(-50%) !important;
                transform: translateY(-50%) !important;
            }
        }

        .text {
            padding-left: 30px !important;
        }

        &.mtb-10 {
            margin-bottom: 10px !important;
            margin-top: 10px !important;
            display: block !important;
            font-weight: 500 !important;
        }
    }

    .has-bot-image {
        .bot-msg-wrapper .bot-img {
            display: block !important;
        }
    }

    .chat-nib {
        display: none !important;
    }

    .has-curved-nib {
        .bot-msg-wrapper .message-block {
            border-radius: 10px !important;
        }

        .bot-msg-wrapper .chat-nib {
            position: relative !important;
            display: block !important;

            svg {
                position: absolute !important;
                left: -22px !important;
                bottom: -17px !important;
                width: 23px !important;
                height: 23px !important;
            }

            // &::after{
            //     content: "";
            //     width: 17px;
            //     height: 17px;
            //     border-radius: 50%;
            //     box-shadow: 4px 7px 0 0 var(--bot-bubble-bg);
            //     position: absolute;
            //     left: -14px;
            //     transform: rotate(3deg);
            //     bottom: 11px;
            //     z-index: 1;
            // }
            &.agent-msg {
                &::after {
                    box-shadow: 4px 12px 0 0 var(--agent-bubble-bg) !important;
                }
            }
        }

        .user-msg-wrapper {
            .message-block {
                border-radius: 10px !important;
            }
        }

        .user-msg-wrapper .chat-nib {
            position: relative !important;
            display: block !important;

            svg {
                position: absolute !important;
                right: -9px !important;
                bottom: 1px !important;
                width: 23px !important;
                height: 23px !important;
            }

            // &::after{
            //     content: "";
            //     width: 11px;
            //     height: 17px;
            //     border-radius: 50%;
            //     box-shadow: 5px 7px 0 0 var(--user-bubble-bg);
            //     position: absolute;
            //     right: -13px;
            //     transform: rotate(104deg);
            //     bottom: 4px;
            //     z-index: 1;
            // }
        }
    }

    .quick-btn-parent-wrap {

        // position: relative;
        .chat-nib {
            display: none !important;
        }

        img {
            margin-bottom: 14px !important;
        }

        .message-block {
            min-width: 93% !important;
        }

        .recommended-text {
            font-size: 10px !important;
            color: var(--bot-bubble-color) !important;
            margin-bottom: 10px !important;
            display: block !important;
            font-style: italic !important;
            opacity: 0.5 !important;
        }

        .quick-toggler {
            position: absolute !important;
            bottom: -20px !important;
            right: 17px !important;
            color: #000 !important;
            background: #D9D9D9 !important;
            font-size: 11px !important;
            padding: 2px 15px !important;
            border-radius: 0 0 5px 5px !important;
            cursor: pointer !important;
            display: flex !important;
            align-items: center !important;

            svg {
                font-size: 16px !important;
            }
        }

        .quick-msg-btn-wrapper {
            background-color: var(--bot-bubble-bg) !important;
            // max-width: 93%;
            padding: 10px !important;
            // margin-top: 15px !important;
            width: 100% !important;
            border-radius: 10px !important;
            margin-bottom: 17px !important;
            position: relative !important;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
            margin-left: 10px !important;

            button {
                margin-bottom: 9px !important;
                margin-right: 9px !important;
            }
        }
    }

    .bot-msg-wrapper {
        &.hideBotImg {
            &.has-title-with-button .bot-msg-btn-wrapper {
                margin-top: 0 !important;
            }

            .message-block {
                border-radius: 7px !important;
            }

            .chat-nib {
                display: none !important;
            }
        }

        &.hideBotImg .bot-img {
            display: none !important;
        }
    }

    .has-bot-image .bot-msg-wrapper {
        &.hideBotImg {
            padding-left: 35px !important;
        }
    }

    &.voice-chat-wrapper .has-bot-image .bot-msg-wrapper {
        &.hideBotImg {
            padding-left: 0 !important;
        }
    }

    .no-internet-wrapper {
        position: absolute !important;
        background-color: #fff !important;
        height: calc(100% - 121px) !important;
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        justify-content: center !important;
        z-index: 999 !important;
        height: 0 !important;
        visibility: hidden !important;
        transition: 0.4s all linear !important;
        overflow: hidden !important;
        top: 75px;

        img {
            width: 100% !important;
        }
    }

    .edit-form-wrapper {
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        width: 100% !important;
        // padding: 20px !important;
        background-color: #fff !important;
        height: 0 !important;
        overflow: hidden !important;
        z-index: 9999 !important;

        form {
            height: 100%;
        }

        &.show-edit-form {
            height: 100% !important;
        }

        .MuiTextField-root,
        .MuiFormControl-root {
            width: 100% !important;
            margin: 0 !important;
            margin-bottom: 10px !important;

            .MuiInput-root::after,
            .MuiInput-root::before {
                border-bottom: 2px solid #bbbbbb !important;
            }

            .MuiInputBase-root {
                margin-top: 20px !important;
            }
        }

        .edit-header {
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            padding: 20px !important;

            // padding-bottom: 0 !important;
            h4 {
                margin: 10px 0 !important;
            }

            svg {
                cursor: pointer !important;
            }
        }

        .edit-form-body {
            height: calc(100% - 168px) !important;
            overflow-y: auto !important;
            padding: 20px !important;
            padding-top: 0 !important;
        }

        .edit-footer {
            padding: 20px !important;
            padding-top: 10px !important;

            .btn-default {
                padding: 10px !important;
                width: 100% !important;
                border-radius: 4px !important;
            }
        }
    }

    .successfully-connected-wrapper {
        font-size: 13px !important;
        position: relative !important;

        &.show-bot-div {
            animation: cssAnimation 0s 3s forwards !important;
            visibility: hidden !important;
        }

        .content {
            width: fit-content !important;
            margin: 15px auto !important;
            background-color: #fff !important;
            padding: 4px 10px !important;
            border-radius: 17px !important;
            z-index: 4 !important;
            position: relative !important;
            max-width: 88% !important;
            text-align: center !important;
        }

        &:after {
            content: '' !important;
            position: absolute !important;
            width: 100% !important;
            height: 2px !important;
            border-bottom: 2px dashed #afadad !important;
            top: 50% !important;
            left: 0 !important;
            transform: translateY(-50%) !important;
            z-index: 1 !important;
        }
    }

    .no-internet-wrapper.no-i-visible {
        visibility: visible !important;
        height: calc(100% - 107px) !important;
    }

    .iframe-wrapper {
        .close-iframe {
            position: absolute;
            top: 88px;
            right: 7px;
            z-index: 99;
            cursor: pointer;
        }
    }

    &.voice-chat-wrapper {
        .user-msg-wrapper {
            .message-block {
                background-color: transparent !important;
                border: none !important;
                box-shadow: none !important;
                // color: #555555 !important;
                text-align: right !important;
                // padding: 5px !important;
                font-size: 17px !important;
                font-weight: 600 !important;
            }

            .chat-nib,
            .bot-img,
            .message-agent,
            .message-timing {
                display: none !important;
            }
        }

        .btn-seprate {
            margin-left: 27px;
        }

        .bot-msg-btn-wrapper {
            background-color: transparent !important;
            box-shadow: none !important;
            border: none !important;
            padding: 0 6px !important;
        }

        .bot-msg-wrapper {
            .message-block {
                background-color: transparent !important;
                border: none !important;
                box-shadow: none !important;
                // color: #555555 !important;
                font-size: 16px !important;
                // padding: 5px !important;
                font-weight: normal !important;
            }

            .chat-nib,
            .message-agent,
            .message-timing {
                display: none !important;
            }
        }
    }

    .msg-parent-wrapper {
        width: max-content;
        max-width: 88%;
        margin-left: 15px;

        &.msg-parent-has-btn {
            width: 100%;
        }

        &.chat-rated {
            margin-bottom: 18px;
        }

        .relative-wrapper {
            display: block;
            position: relative;
            z-index: 10;
        }

        .thumb-rates {
            display: inline-block;
            position: absolute;
            color: #28a745;
            position: absolute;
            right: 3px;
            bottom: 2px;
            background-color: var(--bot-bubble-bg);
            height: 22px;
            border-radius: 6px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

            &.negative {
                color: #dc3545;
            }

            svg {
                font-size: 17px;
            }
        }

        .message-title {
            width: max-content;
            max-width: 100%;
            line-height: 25px !important;
            .test-html, div{
                line-height: 25px !important;
            }
        }

        &:hover {
            .hover-feedback-wrapper {
                display: inline-block;
                position: absolute;
                top: -14px;
                right: 0;
                background-color: #fff;
                padding: 5px 5px;
                border-radius: 6px;
                box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;

                svg {
                    font-size: 17px;
                    margin: 0 3px;
                    cursor: pointer;
                    position: relative;
                }

                span.active {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        right: 5px;
                        width: 62%;
                        height: 2px;
                        background-color: var(--btn-bg);
                    }
                }
            }
        }

        &.first-bot-message .hover-feedback-wrapper {
            top: -4px;
        }

        .feedback-rate-wrapper {
            display: none;
            align-items: center;
            justify-content: space-between;
            // position: absolute;
            width: 100%;
            padding: 0;
            margin-top: 9px;
            font-size: 12px !important;
            padding-right: 0;

            .icon-wrapper {
                display: flex;
                align-items: center;

                svg {
                    font-size: 18px;
                    margin: 0 3px;
                    cursor: pointer;
                }
            }
        }

        &.show-msg-ratings {
            .hover-feedback-wrapper {
                display: none;
            }

            .feedback-rate-wrapper {
                display: flex;
            }
        }
    }

    .custom-full-width-bot {
        .msg-parent-wrapper {
            max-width: 99%;
        }
    }

    &.voice-chat-wrapper {
        .msg-parent-wrapper {
            .thumb-rates {
                left: 5px;
                bottom: -16px;
            }
        }
    }

    .has-bot-image {
        .bot-msg-wrapper .message-block {
            margin-left: 10px !important;
        }

        .msg-parent-wrapper {
            max-width: 77%;
            margin-bottom: 28px;

            .thumb-rates {
                left: 16px;
            }
        }

        .relative-wrapper {
            height: 0px;
            width: calc(100% - 42px);
            margin-left: 42px;
        }

        .feedback-rate-wrapper {
            padding: 0;
            margin-left: 4px;
        }
    }

    &.voice-chat-wrapper {
        .has-bot-image {
            .bot-msg-wrapper .message-block {
                margin-left: 0 !important;
            }
        }

        .chat-body-wrapper.has-chat-screen {
            height: calc(100% - 176px) !important;
        }

        .relative-wrapper {
            margin-left: 0;
        }

        .msg-parent-wrapper {
            max-width: calc(100% - 2px);

            .thumb-rates {
                left: 5px;
            }
        }
    }

    .hide-feedback-wrap {
        .feedback-rate-wrapper {
            display: none !important;
        }

        .hover-feedback-wrapper {
            display: none !important;
        }

        .thumb-rates {
            display: none !important;
        }
    }

    &.its-mobile-app {
        .chat-header-wrapper {
            padding-top: 50px !important;
        }

        .chat-footer-wrapper {
            padding-bottom: 35px !important;
        }

        .chat-send-msg-wrapper {
            bottom: 50px !important;
        }

        .chat-body-wrapper.has-chat-screen {
            height: calc(100% - 238px) !important;
        }

        .loading-comp {
            // margin-top: 103px !important;
        }
    }

    .hidden-input {
        display: none;
    }

    .pdf-sideview-wrapper {
        position: absolute !important;
        top: 0 !important;
        width: 100% !important;
        height: 0 !important;
        overflow-y: auto !important;
        // overflow-x: hidden;
        background-color: #fff;
        z-index: 99999 !important;
        // padding: 14px !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
        right: -200% !important;
        // overflow: hidden;
        transition: 0.3s all linear;

        @media only screen and (max-width: 767px) {
            width: 100% !important;
        }

        .pdf-wrap-header {
            padding: 15px !important;
            border-bottom: 1px solid #ccc !important;
            display: flex !important;
            align-items: center !important;
            justify-content: space-between !important;
            min-height: 74px !important;
            position: sticky;
            top: 0;
            z-index: 9;
            background-color: #fff;

            h6 {
                font-size: 18px !important;
                font-weight: 500 !important;
                margin: 0 !important;
            }
        }

        .pdf-wrap-body {
            padding: 15px !important;

            iframe {
                height: calc(100vh - 108px);
            }
        }

        .close-pdf-icon {
            // position: fixed !important;
            // right: 20px !important;
            // top: 20px !important;
            // z-index: 999999 !important;
            font-size: 28px !important;
            color: var(--btn-bg) !important;
            cursor: pointer !important;
        }

        &.show {
            right: 0 !important;
        }

        // canvas {
        //     width: 100% !important;
        //     height: auto !important;
        // }
    }

    .pdf-sidebar-main-wrapper {
        // display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .right-pdf-icon-wrap {
        max-width: max-content;
        display: flex;
        align-items: center;
        border: 1px solid var(--btn-bg);
        border-radius: 4px;
        padding: 10px;
        cursor: pointer;
        margin-right: 15px;
        margin-bottom: 15px;
        border: none;
        padding: 5px;
        margin-bottom: 6px !important;
        background-color: rgba(255, 255, 255, 0.2);
        min-width: 129px;
        &:hover {
            background-color: var(--btn-bg);
            color: #fff;
        }
        &.open-url-btn{
            color: var(--btn-bg) !important;
            .pdf-detail-wrapper {
                font-size: 15px !important;
            }
            &:hover{
                color: var(--btn-color) !important;
            }
        }
        img {
            margin-right: 10px;
            width: 25px !important;
            height: 25px !important;
            border-radius: 0 !important;
        }
        .pdf-detail-wrapper{
            font-size: 12px !important;
        }
    }
    .refrence-wrapper{
        margin-bottom: 20px;
        padding-left: 21px;
        @media only screen and (max-width: 767px) {
            padding-left: 0;
        }
        .refrence-title{
            font-weight: bold;
            margin-top: 20px !important;
            margin-bottom: 12px;
            font-size: 10px;
            color: #afa8a8;        
        }
    }
    .hidden-closec-btn{
        opacity: 0 !important;
        width: 0 !important;
        height: 0 !important;
    }
    .message-block{
        a{
            color: #7EF1FE !important;
        }
    }
}
.has-pdf-sidebar-open {
    .pdf-sideview-wrapper{
        height: 100% !important;
    }
}
.theme-vintage{
    
}

.MuiPopover-root {
    z-index: 999999 !important;
}

@keyframes cssAnimation {
    to {
        visibility: visible !important;
    }
}

@keyframes cssAnimation2 {
    to {
        visibility: hidden !important;
        height: 0 !important;
        overflow: hidden !important;
        margin-top: 0 !important;
        width: 0 !important;
        position: fixed !important;
        left: 0 !important;
    }
}
