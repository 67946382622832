.talkk-web-chatbot-main-wrap {

    .bg-language-page {
        margin: 0 !important;
    }

    .landing-language-page {
        background: #f9f9f9 !important;
        height: 100% !important;
        width: 100% !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        position: relative !important;
        padding: 0 !important;
        margin: 0 !important;
        position: absolute !important;
        top: 0 !important;
        margin-top: 72px !important;
        max-height: calc(100% - 71px) !important;
        z-index: 1 !important;
        border-radius: 0 0 19px 19px !important;

        h3 {
            color: #20336C !important;
        }

        .language-wrapper {
            display: flex !important;
            align-items: center !important;
            justify-content: center !important;
            margin-top: 40px !important;

            .lang-box {
                background-color: #fff !important;
                padding: 8px 14px !important;
                border-radius: 5px !important;
                color: #20336C !important;
                margin: 0 10px !important;
                cursor: pointer !important;
                min-width: 68px !important;

                &:hover {
                    background: var(--header-bg-color) !important;
                    color: var(--header-color) !important;

                    p {
                        color: var(--header-color) !important;
                    }
                }
            }

            p {
                margin: 0 !important;
                font-size: 12px !important;
            }

            .lang-name {
                font-weight: 700 !important;
                text-transform: uppercase !important;
                font-size: 20px !important;
                margin-bottom: 4px !important;
            }
        }

        .select-langauge-section {
            position: absolute !important;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
            width: 250px !important;
            text-align: center !important;
            color: var(--header-color) !important;

            h3 {
                text-align: center !important;
            }

            .select-buttons {
                width: 100% !important;
                text-align: center !important;
                // position: absolute !important;
                // top: 50% !important;
                // left: 50% !important;
                // transform: translate(-50%, -50%) !important;
                display: inline-block !important;
                padding-left: 0 !important;

                li {
                    display: inline-block !important;
                    list-style: none !important;
                    width: 60px !important;
                    margin: 6px !important;

                    button {
                        padding: 0 !important;
                        width: 100% !important;
                        border-radius: 5px !important;
                        background-color: #fff !important;
                        color: #000 !important;
                        font-weight: 500 !important;
                        font-size: 16px !important;
                        border: 0 !important;
                        offset: none !important;
                        cursor: pointer !important;

                        p {
                            font-size: 11px !important;
                            margin: 0 !important;
                        }
                    }
                }
            }
        }

    }
}