.talkk-web-chatbot-main-wrap {
  .star-rating {
    direction: rtl !important;
    display: inline-block !important;
    padding: 5px !important;
    cursor: default !important;

    input[type="radio"] {
      display: none !important;
    }

    label {
      color: #bbb !important;
      font-size: 24px !important;
      padding: 5px !important;
      padding: 0 !important;
      cursor: pointer !important;
      -webkit-transition: all 0.3s ease-in-out !important;
      transition: all 0.3s ease-in-out !important;
      svg,path{
        color: #bbb !important;
      }
    }

    label:hover,
    label:hover~label,
    input[type="radio"]:checked~label {
      color: #f2b600 !important;
      svg,path{
        color: #f2b600 !important;
      }
    }

    svg {
      font-size: 25px;
    }
  }

  .PhoneInput {
    .PhoneInputCountry {
      color: #495057 !important;
    }
  }
  .negative-rate-wrapper{
      .drawer-body{
        text-align: left !important;
      }
      .form-action-group{
        margin-top: 30px !important;
      }
      .form-control{
        &:focus, &:hover{
          box-shadow: none !important;
        }
      }
      .closebar-toggler{
        display: none;
      }
      .error{
        color: #db3636;
        margin-top: 7px;
        font-size: 14px;
      }
  }
  .leave-message-wrapper{
    .closebar-toggler{
      display: none;
    }
  }
}