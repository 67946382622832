.theme-mumbai-port{
    .chat-header-wrapper {
        min-height: 70px !important;
    }
    .chat-subheader-wrapper {
        display: block !important;
    }
    .chat-header-wrapper .header-wave {
        display: block;
    }
    .floating-card {
        display: none;
    }
    .floating-nibs{
        display: block;
        position: fixed;
        bottom: 26px;
        right: 59px;
        overflow: hidden;
        width: 20px;
        height: 44px;
        max-width: 350px;
        transition: 0.3s all linear;
        background-color: var(--header-bg-color);
        color: var(--header-color);
        border-radius: 50px;
        display: flex;
        align-items: center;
        min-height: 58px;
        font-size: 12px;
        font-weight: bold;
        z-index: 999 !important;
        &.hide-float{
            width: 20px !important;
            padding: 0 !important;
        }
        @media only screen and (max-width : 767px) {
            display: none;
        }
    }
    .mini-bot-main-wrap:hover{
        .floating-nibs{
            width: 320px;
            min-height: 58px;
            padding: 8px;
            padding-right: 50px;
            padding-left: 42px;
        }
    }
}
