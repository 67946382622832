@import "./variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2vwr56');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2vwr56#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2vwr56') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2vwr56') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2vwr56##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.ttd {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ttd-idea1 {
  &:before {
    content: $ttd-idea1;     
    color: #939393;
  }
}
.ttd-up-arrow {
  &:before {
    content: $ttd-up-arrow;     
    color: #fff;
  }
}
.ttd-menu {
  &:before {
    content: $ttd-menu;     
    color: #939393;
  }
}
.ttd-time {
  &:before {
    content: $ttd-time;     
    color: #939393;
  }
}
.ttd-thumbup {
  &:before {
    content: $ttd-thumbup;     
    color: #939393;
  }
}
.ttd-thumbdown {
  &:before {
    content: $ttd-thumbdown;     
    color: #939393;
  }
}
.ttd-copy {
  &:before {
    content: $ttd-copy;     
    color: #939393;
  }
}
.ttd-save {
  &:before {
    content: $ttd-save;     
    color: #939393;
  }
}
.ttd-notification {
  &:before {
    content: $ttd-notification;     
    color: #999;
  }
}
.ttd-history1 {
  &:before {
    content: $ttd-history1;     
    color: #777;
  }
}
.ttd-feed {
  &:before {
    content: $ttd-feed;     
    color: #777;
  }
}
.ttd-pdf1 .path1 {
  &:before {
    content: $ttd-pdf1-path1;  
    color: rgb(229, 37, 42);
  }
}
.ttd-pdf1 .path2 {
  &:before {
    content: $ttd-pdf1-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);  
    opacity: 0.3020;
  }
}
.ttd-pdf1 .path3 {
  &:before {
    content: $ttd-pdf1-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ttd-idea {
  &:before {
    content: $ttd-idea;     
    color: #939393;
  }
}