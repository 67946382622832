$icomoon-font-family: "ttd" !default;
$icomoon-font-path: "../../../fonts/ttd/" !default;

$ttd-idea1: unquote('"\\e90e"');
$ttd-up-arrow: unquote('"\\e90d"');
$ttd-menu: unquote('"\\e900"');
$ttd-time: unquote('"\\e901"');
$ttd-thumbup: unquote('"\\e902"');
$ttd-thumbdown: unquote('"\\e903"');
$ttd-copy: unquote('"\\e904"');
$ttd-save: unquote('"\\e905"');
$ttd-notification: unquote('"\\e906"');
$ttd-history1: unquote('"\\e907"');
$ttd-feed: unquote('"\\e908"');
$ttd-pdf1-path1: unquote('"\\e909"');
$ttd-pdf1-path2: unquote('"\\e90a"');
$ttd-pdf1-path3: unquote('"\\e90b"');
$ttd-idea: unquote('"\\e90c"');

