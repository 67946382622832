.esoft {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    color: #fff;

    .talkk-chat-wrapper-c {
        .mobilink-wrapper {
            max-width: 100% !important;
            min-width: 100% !important;
            padding: 0 !important;
            // background: url('../../../assets/images/esoft-bg.png') !important;
            position: relative;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: transparent !important;
        }

        .video-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.2; // 20% opacity for the GIF
            z-index: -1;
            @media only screen and (max-width: 1023px) {
                object-fit: cover;
            }
        }

        .bg-img-overlay {
            position: fixed;
            /* Set to fixed to match the video positioning */
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url('../../../assets/images/esoft-bg.png') no-repeat center center;
            background-size: cover;
            /* Ensure the background covers the full space */
            z-index: -2;
            &.show-chat-overlay{
                background: url('../../../assets/images/esoft-chat-bg.png') no-repeat center center;
                background-size: cover;
            }
            // @media only screen and (max-width: 1023px) {
            //     background: rgba(4, 15, 33, 1) !important;
            // }
        }
    }
}

.esoft-header {
    position: relative;
}

.esoft-header-inner {
    display: flex;
    gap: 32px;
    font-weight: 500;
    font-size: 14px;
    justify-content: center;
    // padding: 15px;
    max-width: 700px;
    margin: 0 auto;
    z-index: 999;
    position: relative;

    span {
        cursor: pointer;
        transition: all 0.3s;
    }

    span:hover {
        // text-shadow: 1px 1px 1px #fff,    }
        color: #7ECDFE;
        // font-weight: 600;
    }
}

.esoft-header-full {
    margin: 40px 0 40px 0;
}

.esoft-header-half {
    margin: 40px 0 40px 0
}

.esoft-logo-header {
    position: absolute;
    top: 30px;
    left: 30px;

    img {
        width: 110px;
        height: auto;
    }
}


.esoft-welcome-subpara {
    font-size: 20px;
    margin: 10px 0 30px 0;
    color: #9DA4AD;
}

.talkktodoc-main-wrap .chat-send-msg-wrapper,
.talkktodoc-main-wrap .chat-body-wrapper {
    background: none !important;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
}
.talkktodoc-main-wrap{
    @media only screen and (max-width:1023px) {
        .chat-inner-content-full{
            height: auto !important;
        }
        .chat-inner-content {
            min-height: auto !important;
        } 
        .chat-send-msg-wrapper{
            position: fixed !important;
            bottom: 0 !important;
            margin: 20px !important;
            width: 92% !important;
        }
    }
    .close-chat-btn-b{
        position: absolute;
        right: 61px;
        top: -11px;
        z-index: 999;
        @media only screen and (max-width: 1023px) {
            display: none;
        }
        span{
            width: 100%;
            text-align: center;
        }
    }
}

// conditionally half full
.esoft-chatbody-wrp-full {
    height: calc(100% - 99px);
    @media only screen and (max-width: 1023px) {
        height: auto !important;   
    }
}

.esoft-typebar-full {
    width: 100% !important;
}

.esoft-typebar-half {
    width: calc(100% - 110px) !important;
}

.talkk-chat-wrapper-c .has-voice-chat .typebar-fields {
    border-radius: 45px !important;
    background: rgba(255, 255, 255, 0.2) !important;
    padding: 6px !important;
    border: none !important;
    margin-top: 25px !important;
    width: 100%;
    min-width: 610px !important;
}

.typebar-fields .form-control {
    border-radius: 40px !important;
    background: none !important;
    margin-left: 40px;
    color: #fff !important;
}

.talkktodoc-main-wrap .no-chat-wrapper {
    padding: 0 !important;
}

.talkktodoc-main-wrap .no-chat-wrapper .no-chat-heading {
    margin: 10px 0 0 0 !important;
    font-size: 40px !important;
}

.talkktodoc-main-wrap {
    .chat-body-wrapper {
        background: none !important;

        &::-webkit-scrollbar {
            width: 2px;
            /* Set scrollbar width */
        }

        &::-webkit-scrollbar-track {
            background-color: rgba(255, 255, 255, 0.1);
            /* Set background color */
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(255, 255, 255, 0.4);
            /* Color for the scrollbar thumb */
        }
    }
}

.talkktodoc-main-wrap .chat-inner-content {
    width: calc(100vw - 400px);
    margin: 0 auto;
    // background: none !important;
}

.talkktodoc-main-wrap .chat-inner-content-half {
    background: none !important;
}

.chat-inner-content::-webkit-scrollbar {
    display: none;
}

.chat-inner-content-full {
    // border: 1px solid #999;
    // height: 100vh;
    // box-shadow: 1px 1px 2px 3px #FFFFFF4D inset;
    overflow: auto;
    // height: calc(100vh - 110px);
    // height: calc(100vh - 231px);
    height: calc(var(--mobile-height) - 110px);
    border-bottom: none;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border: 1px solid rgb(255, 255, 255, 0.3);
    // background: rgb(255, 255, 255, 0.06) !important;
    box-shadow: 2px 2px 24px 2px rgba(255, 255, 255, 0.1) inset;
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
    bottom: 0;
    @media only screen and (max-width: 1023px) {
        border-radius: 60px !important;
        background: transparent;
        border: none;
        box-shadow: none;
        border-radius: 0 !important;
    }
}

.message-block {
    color: #fff !important;
}

// Typebar code 
.esoft-typebar-full {
    width: 100% !important;
}

.esoft-typebar {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.btn-landing-send {
    cursor: pointer;
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;

    // right: -110px;
    img {
        position: relative;
        width: 136px;
        height: auto;
        // border-radius: 40px;
        transition: all 0.3s;
    }

    img:hover {
        transform: scale(1.04);
        // box-shadow: 1px 1px 1px #fff;
    }


    span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
    }
}

.type-input-wrp {
    width: 100%;
}

.border-esoft {
    position: absolute;
    top: -19px;
    left: 0%;
    object-fit: contain;
    width: 100%;
    height: 55px;
}


// Inner Chat Body Code : 

.message-block {
    background: transparent !important;
}

// Send Buttons Display 
.btn-landing-send-mobile {
    display: none;
}

// Buttons to be hidden on desktop 
.esoft-menu-icon {
    display: none;
}

.esoft-mobile-logo-icon {
    display: none;
}

// Feedback buttons 
.attachment-wrapper{
    margin-top: 15px;
}

.talkk-chat-wrapper-c .bot-extracted-question {
    position: relative;
    padding-left: 20px;
    margin-bottom: 13px;
    line-height: 25px !important;
    img{
        position: absolute;
        left: -13px;
        // width: 25px !important;
        // height: 25px !important;
        top: -2px;
        @media only screen and (max-width: 1023px) {
            top: 4px;
        }
    }
}
.esoft-feedback-buttons {
    display: flex;
    gap: 20px;
    // font-size: 16px;
    margin: 10px 0 5px 20px;
    align-items: center;
    padding-left: 16px;
    span {
        cursor: pointer;
        transition: color 0.2s;
        width: auto !important;
        height: auto !important;
        font-size: 19px !important;
        color: rgb(255,255,255,0.7);
        outline: none !important;
        margin-right: 18px;
        svg{
            outline: none !important;
            width: 18px;
            height: 18px;
        }
        &:hover {
            color: rgb(255,255,255,1);
            // color: #7ECDFE;
        }
        // &:active {
        //     border: 1px solid transparent;
        // }
    }
    .footer-share-icon{
        font-size: 26px !important;
        margin-top: -6px;
        svg{
            width: 17px;
            height: 17px;
        }
    }
    .footer-copy-icon{
        margin-top: -6px;
        font-size: 26px !important;
        svg{
            width: 15px;
            height: 15px;        
        }
    }
    .footer-speaker-icon{
        font-size: 26px !important;
        svg{
            width: 20px;
            height: 20px;
        }
    }
}
.mobile-message-wrapper{
    max-width: 600px;
    margin: auto;
}
.talkk-chat-wrapper-c .msg-parent-wrapper:hover .hover-feedback-wrapper {
    top: 102px;
    left: 0;
    right: unset;
    background: none;
    display: flex;
    gap: 20px;
    box-shadow: none;
    display: block;
}

.stars-gif {
    position: absolute;
    width: 100%;
}

.stars-gif img {
    display: none; /* Hide all GIFs initially */
    position: absolute;
}

.star-one {
    top: 100px;
    left: 120px;
}

.star-two {
    right: 20px;
}

.star-three {
    top: 300px;
    right: 40px;
}

.star-four {
    top: 500px;
    left: 100px;
}

.star-five {
    top: 250px;
    left: 30px;
}

.star-six {
    top: 450px;
    right: 25px;
}


// Media Queries

@media only screen and (max-width: 1200px) {
    .talkktodoc-main-wrap .chat-inner-content {
        width: calc(100vw - 200px);
    }
}

@media only screen and (max-width: 900px) {
    .talkktodoc-main-wrap .chat-inner-content {
        width: calc(100vw - 100px);
    }

    .esoft-logo-header {
        display: none;
    }

}

@media only screen and (max-width : 1023px) {
    .talkktodoc-main-wrap .no-chat-wrapper{
        height: var(--mobile-height) !important;
        position: fixed;
        position: absolute;
        bottom: 0;
        padding-top: 14vh !important;
    }
    .talkktodoc-main-wrap .chat-inner-content {
        width: calc(100vw - 10px);
    }

    .talkktodoc-main-wrap .chat-inner-content .chat-inner-layout {
        max-width: calc(100% - 30px);
        margin: 0 auto;
    }

    .esoft-logo-header {
        display: none;
    }

    .esoft-mobile-logo-icon {
        position: absolute;
        left: 50%;
        top: 29px;
        padding: 10px;
        border-radius: 50%;
        height: 70px;
        width: 70px;
        z-index: 999;
        background-color: transparent;
        transform: translateX(-50%);
        // &::before{
        //     content: "";
        //     width: 20px;
        //     height: 20px;
        //     background-color: #000;
        //     border-radius: 25px 5px 73px 0px;
        //     position: absolute;
        //     bottom: 29px;
        //     left: -5px;
        //     transform: rotate(387deg);
        //     z-index: -1;
        // }
        // &::after{
        //     content: "";
        //     width: 20px;
        //     height: 20px;
        //     background-color: #000;
        //     border-radius: 25px 5px 73px 0px;
        //     position: absolute;
        //     bottom: 29px;
        //     right: -5px;
        //     transform: rotate(235deg);
        //     z-index: -1;
        // }
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
    }

    .esoft-welcome-header {
        height: calc(100vh - 99px) !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .esoft-header-inner {
        visibility: hidden;
    }

    .esoft-header-full {
        margin: 20px 0 0 0;
    }

    .talkktodoc-main-wrap .no-chat-wrapper .chat-send-msg-wrapper {
        // position: relative !important;
        // bottom: -130px !important;
        position: absolute !important;
        bottom: 44px !important;
        // width: 100% !important;
        margin: 0 !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }

    .talkktodoc-main-wrap .no-chat-wrapper .typebar-fields.only-typebar-field .send-icon-wrap {
        right: -58px;
        left: unset;
        /* background: rgba(255, 255, 255, 0.1) !important; */
        width: 49px !important;
        height: 49px !important;
    }

    .typebar-fields .form-control {
        margin-left: 5px;
    }

    .animation-placeholder {
        display: none;
    }

    .talkktodoc-main-wrap .no-chat-wrapper .typebar-fields .form-control::placeholder {
        color: #fff;
    }

    .talkk-chat-wrapper-c .has-voice-chat .typebar-fields {
        min-width: 300px !important;
        margin-right: 0px !important;
        margin-left: -10px !important;
    }

    .talkk-chat-wrapper-c .bot-msg-wrapper .message-block {
        font-size: 14px !important;
    }

    .btn-landing-send-mobile {
        display: block;
        right: 12px;
        top: 50%;
        position: relative;
    }

    .btn-landing-send {
        display: none;
    }

    .talkk-chat-wrapper-c .user-msg-wrapper {
        font-size: 14px !important;
    }

    .esoft-logo-header {
        display: none;
    }

    .border-esoft {
        display: none;
    }
    .talkktodoc-main-wrap .no-chat-wrapper .typebar-fields .form-control {
        height: 35px !important;
        font-size: 14px !important;
        padding-right: 35px !important;
    }
    .btn-landing-send-mobile img {
        width: 20px;
        height: 20px;
    }
    .stars-gif img {
        width: 80px !important;
        height: 80px !important;
    }

    // Mobile menu icon code 
    .esoft-mobile-logo-icon {
        display: block;
    }

    .esoft-menu-icon {
        position: absolute;
        border-radius: 50%;
        background: linear-gradient(180deg, rgb(255, 255, 255, 0.1) 0%, rgba(153, 153, 153, 0) 123.41%);
        top: 11px;
        right: 8px;
        font-size: 30px;
        height: 50px;
        width: 50px;
        text-align: center;
        cursor: pointer;
        z-index: 99;
        display: block;
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        &.close-chat-btn{
            left: 8px !important;
        }
        display: block;
    }

    .menu-modal-wrp {
        background: #000;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999999;
        .close-iframe{
            position: fixed !important;
            top: 15px;
            right: 15px;
            font-size: 35px;
        }
        .esoft-modal-logo {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 50px;
            max-width: 100px;
        }

        .menu-modal-lists {
            display: flex;
            text-align: center;
            flex-direction: column;
            gap: 20px;
            // font-size: 18px;
            position: absolute;
            top: 24%;
            left: 43px;

            span {
                cursor: pointer;
                text-wrap: nowrap;
                font-size: 25px;
                text-align: left;
                font-weight: 700;

                &:nth-child(even) {
                    color: #2558AE;
                }
            }
        }

        .esoft-modal-poweredby {
            position: absolute;
            bottom: 55px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    

}

@media only screen and (max-width : 767px) {
    .talkk-chat-wrapper-c  .typebar-fields {
        width: 83% !important;
    }
}

@media(max-width : 500px) {
    // .talkktodoc-main-wrap .no-chat-wrapper .chat-send-msg-wrapper {
    //     bottom: -100px !important;
    // }

    .esoft-typebar-half {
        width: calc(100% - 90px) !important;
    }
    .talkk-chat-wrapper-c .has-voice-chat .typebar-fields{
        margin-left: 0 !important;
        margin-top: 0 !important;
    }
    
}

// @media(max-width : 400px) {
//     .talkk-chat-wrapper-c .has-voice-chat .typebar-fields {
//         margin-left: -20px !important;
//     }
   
// }